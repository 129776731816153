import React, { useState, useEffect } from 'react';
import api from '../../components/api/api'; // Certifique-se que o axios está configurado corretamente

const Sobre = () => {
    const [eventDetails, setEventDetails] = useState({ title: '', description: '', description_shorts: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);
    const [isEditingShortDescription, setIsEditingShortDescription] = useState(false);

    useEffect(() => {
        api.get(`/eventos/${localStorage.getItem("idevento")}`) // Substitua pela URL correta da sua API
            .then(response => {
                const { title, description, description_shorts } = response.data.evento; 
                setEventDetails({ title, description, description_shorts });
                setLoading(false);
            })
            .catch(error => {
                console.error('Erro ao buscar detalhes do evento:', error);
                setError('Erro ao carregar os detalhes do evento.');
                setLoading(false);
            });
    }, []);

    // Função para salvar os dados na API
    const handleSave = () => {
        const token = localStorage.getItem("token");
        api.put(`/eventos/${localStorage.getItem("idevento")}`, 
        {
            title: eventDetails.title,
            description: eventDetails.description,
            description_shorts: eventDetails.description_shorts // Adicionando o description_shorts no PUT
        }, 
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        .then(response => {
            console.log('Detalhes atualizados:', response.data);
            setIsEditingTitle(false);
            setIsEditingDescription(false);
            setIsEditingShortDescription(false);
        })
        .catch(error => {
            console.error('Erro ao salvar os detalhes do evento:', error);
            setError('Erro ao salvar os detalhes do evento.');
        });
    };

    if (loading) {
        return <p className="text-gray-700">Carregando...</p>;
    }

    if (error) {
        return <p className="text-red-600">{error}</p>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-4">
            <h2 className="text-2xl font-bold mb-4">Sobre o Evento</h2>

            {/* Editar Título */}
            <div className="mb-4">
                {isEditingTitle ? (
                    <div>
                        <input
                            type="text"
                            value={eventDetails.title}
                            onChange={(e) => setEventDetails({ ...eventDetails, title: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                        <button
                            onClick={handleSave}
                            className="mt-2 bg-green-500 text-white rounded p-2"
                        >
                            Salvar
                        </button>
                        <button
                            onClick={() => setIsEditingTitle(false)}
                            className="mt-2 ml-2 bg-gray-500 text-white rounded p-2"
                        >
                            Cancelar
                        </button>
                    </div>
                ) : (
                    <div className="flex justify-between items-center">
                        <p className="text-gray-700">Título: {eventDetails.title}</p>
                        <button
                            onClick={() => setIsEditingTitle(true)}
                            className="bg-blue-500 text-white rounded p-2"
                        >
                            Editar
                        </button>
                    </div>
                )}
            </div>

            {/* Editar Descrição */}
            <div className="mb-4">
                {isEditingDescription ? (
                    <div>
                        <textarea
                            value={eventDetails.description}
                            onChange={(e) => setEventDetails({ ...eventDetails, description: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                        <button
                            onClick={handleSave}
                            className="mt-2 bg-green-500 text-white rounded p-2"
                        >
                            Salvar
                        </button>
                        <button
                            onClick={() => setIsEditingDescription(false)}
                            className="mt-2 ml-2 bg-gray-500 text-white rounded p-2"
                        >
                            Cancelar
                        </button>
                    </div>
                ) : (
                    <div className="flex justify-between items-center">
                        <p className="text-gray-700">Descrição: {eventDetails.description}</p>
                        <button
                            onClick={() => setIsEditingDescription(true)}
                            className="bg-blue-500 text-white rounded p-2"
                        >
                            Editar
                        </button>
                    </div>
                )}
            </div>

            {/* Editar Descrição Curta */}
            <div className="mb-4">
                {isEditingShortDescription ? (
                    <div>
                        <textarea
                            value={eventDetails.description_shorts}
                            onChange={(e) => setEventDetails({ ...eventDetails, description_shorts: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                        <button
                            onClick={handleSave}
                            className="mt-2 bg-green-500 text-white rounded p-2"
                        >
                            Salvar
                        </button>
                        <button
                            onClick={() => setIsEditingShortDescription(false)}
                            className="mt-2 ml-2 bg-gray-500 text-white rounded p-2"
                        >
                            Cancelar
                        </button>
                    </div>
                ) : (
                    <div className="flex justify-between items-center">
                        <p className="text-gray-700">Descrição curta: {eventDetails.description_shorts}</p>
                        <button
                            onClick={() => setIsEditingShortDescription(true)}
                            className="bg-blue-500 text-white rounded p-2"
                        >
                            Editar
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Sobre;
