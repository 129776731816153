import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import api from '../../components/api/api';

function CriarEvento() {
    const navigate = useNavigate();
    const [nomeEvento, setNomeEvento] = useState(localStorage.getItem('nomeEvento') || '');
    const [descricaoCurta, setDescricaoCurta] = useState(localStorage.getItem('descricaoCurta') || '');
    const [descricaoLonga, setDescricaoLonga] = useState(localStorage.getItem('descricaoLonga') || '');
    const [dataInicioEvento, setDataInicioEvento] = useState(localStorage.getItem('dataInicioEvento') || '');
    const [dataFinalEvento, setDataFinalEvento] = useState(localStorage.getItem('dataFinalEvento') || '');

    useEffect(() => {
        // Carrega dados do localStorage ao iniciar o componente
        setNomeEvento(localStorage.getItem('nomeEvento') || '');
        setDescricaoCurta(localStorage.getItem('descricaoCurta') || '');
        setDescricaoLonga(localStorage.getItem('descricaoLonga') || '');
        setDataInicioEvento(localStorage.getItem('dataInicioEvento') || '');
        setDataFinalEvento(localStorage.getItem('dataFinalEvento') || '');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!nomeEvento || !descricaoCurta || !descricaoLonga || !dataInicioEvento || !dataFinalEvento) {
            alert("Por favor, preencha todos os campos antes de continuar.");
            return;
        }

        const enderecoId = localStorage.getItem('enderecoId');
        const sedeId = localStorage.getItem('sedeId');
        const token = localStorage.getItem('token');

        if (!enderecoId) {
            alert("ID do endereço não encontrado. Por favor, verifique e tente novamente.");
            return;
        }

        if (!sedeId) {
            alert("ID do endereço não encontrado. Por favor, verifique e tente novamente.");
            return;
        }

        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            navigate('/login');
            return;
        }

        const evento = {
            title: nomeEvento,
            description_shorts: descricaoCurta,
            description: descricaoLonga,
            data_inicio: dataInicioEvento,
            data_fim: dataFinalEvento,
            Endereco_id: enderecoId,
            Sede_id: sedeId,
            status: 'ATIVO',
        };

        try {
            const response = await api.post('/eventos', evento, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201 || response.status === 200) {
                console.log('Evento criado com sucesso:', response.data);
                localStorage.setItem('idevento', response.data.id);
                navigate('/criar-upload');
            } else {
                console.error('Erro na resposta da API:', response);
                alert("Erro ao enviar evento! Status: " + response.status);
            }
        } catch (error) {
            console.error('Erro ao enviar evento:', error.response ? error.response.data : error.message);
            alert("Erro ao enviar evento! " + (error.response?.data?.message || error.message));
        }
    };

    const handleFieldChange = (setter, key) => (e) => {
        setter(e.target.value);
        localStorage.setItem(key, e.target.value); // Salva no localStorage ao alterar
    };

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
                <div className="flex items-center mb-11 ml-8">
                    <IoMdArrowRoundBack className="text-2xl text-blue-500 size-7" />
                    <button
                        onClick={() => navigate(-1)}
                        className="text-xl text-blue-500 hover:text-blue-700 font-bold"
                    >
                        VOLTAR
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">CRIAR EVENTO</h2>
                    <form onSubmit={handleSubmit} className="w-full max-w-xs space-y-4">
                        <div>
                            <label htmlFor="nomeEvento" className="block text-sm font-bold mb-1 ml-4">NOME DO EVENTO</label>
                            <input
                                type="text"
                                id="nomeEvento"
                                className="w-full border rounded-full px-3 py-2"
                                value={nomeEvento}
                                onChange={handleFieldChange(setNomeEvento, 'nomeEvento')}
                            />
                        </div>
                        <div>
                            <label htmlFor="descricaoCurta" className="block text-sm font-bold mb-1 ml-4">DESCRIÇÃO CURTA DO EVENTO</label>
                            <input
                                type="text"
                                id="descricaoCurta"
                                className="w-full border rounded-full px-3 py-2"
                                value={descricaoCurta}
                                onChange={handleFieldChange(setDescricaoCurta, 'descricaoCurta')}
                            />
                        </div>
                        <div>
                            <label htmlFor="descricaoLonga" className="block text-sm font-bold mb-1 ml-4">DESCRIÇÃO LONGA DO EVENTO</label>
                            <input
                                type="text"
                                id="descricaoLonga"
                                className="w-full border rounded-full px-3 py-2"
                                value={descricaoLonga}
                                onChange={handleFieldChange(setDescricaoLonga, 'descricaoLonga')}
                            />
                        </div>
                        <div>
                            <label htmlFor="dataInicioEvento" className="block text-sm font-bold mb-1 ml-4">DATA INICIAL DO EVENTO</label>
                            <input
                                type="date"
                                id="dataInicioEvento"
                                className="w-full border rounded-full px-3 py-2"
                                value={dataInicioEvento}
                                onChange={handleFieldChange(setDataInicioEvento, 'dataInicioEvento')}
                            />
                        </div>
                        <div>
                            <label htmlFor="dataFinalEvento" className="block text-sm font-bold mb-1 ml-4">DATA FINAL DO EVENTO</label>
                            <input
                                type="date"
                                id="dataFinalEvento"
                                className="w-full border rounded-full px-3 py-2"
                                value={dataFinalEvento}
                                onChange={handleFieldChange(setDataFinalEvento, 'dataFinalEvento')}
                            />
                        </div>
                        <div className='flex justify-center items-center'>
                            <button type="submit" className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full mt-4 justify-center flex items-center">PRÓXIMO</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CriarEvento;
