import CheckoutMercadoPago from './checkoutMercadoPago.jsx';
export default function Home() {
  return (
    <>
        <title>Checkout Brick</title>
        <meta name="description" content="Generated by create next app" />
      <main className="flex justify-center items-center m-0 mt-10">
        <CheckoutMercadoPago />
      </main>
    </>
  );
}