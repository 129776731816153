import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import MaskedInput from 'react-text-mask';
import logo from "../../assets/LogoIconBranca.png";
import fundoVertical from '../../assets/FOTOS LATEERAIS (2).png';
import api from '../../components/api/api';

function Registro() {
    const navigate = useNavigate();
    const [dadosFormulario, setDadosFormulario] = useState({
        nomeCompleto: '',
        cpf: '',
        dataNascimento: '',
        cep: '',
        email: '',
        telefone: '',
        senha: ''
    });
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [cooldown, setCooldown] = useState(0);
    const [errorMessage, setErrorMessage] = useState(''); // Estado para armazenar mensagens de erro

    const handleChange = (e) => {
        setDadosFormulario({ ...dadosFormulario, [e.target.id]: e.target.value });
        setErrorMessage(''); // Limpa a mensagem de erro quando o usuário modifica os campos
    };

    const salvarInformacoes = async (senha, email, nome, cep, telefone, cpf, dataNascimento) => {
        try {
            const response = await api.post('/users/registrar/', {
                email: email,
                name: nome,
                phone: telefone,
                cep: cep,
                cpf: cpf,
                datebirth: dataNascimento,
                password: senha,
            });

            console.log('Usuário registrado com sucesso:', response.data);
            setCooldown(5); // Define o cooldown para 5 segundos
            setIsButtonDisabled(true); // Desativa o botão imediatamente após o cadastro
            navigate('/login');
        } catch (error) {
            console.error("Erro ao adicionar usuário:", error);

            // Define diferentes mensagens de erro para exibir ao usuário
            if (error.response) {
                // Erros que vêm da resposta da API
                if (error.response.status === 400) {
                    setErrorMessage("Dados inválidos. Por favor, verifique as informações e tente novamente.");
                } else if (error.response.status === 409) {
                    setErrorMessage("Este e-mail já está em uso. Tente usar outro.");
                } else {
                    setErrorMessage("Erro ao registrar. Tente novamente mais tarde.");
                }
            } else if (error.request) {
                // O pedido foi feito, mas nenhuma resposta foi recebida
                setErrorMessage("Falha na conexão. Verifique sua internet e tente novamente.");
            } else {
                // Algo aconteceu ao configurar a requisição
                setErrorMessage("Ocorreu um erro inesperado. Tente novamente.");
            }
        }
    };

    const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
    const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    const telefoneMask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    // Verifica se todos os campos estão preenchidos
    useEffect(() => {
        const allFieldsFilled = Object.values(dadosFormulario).every(value => value !== '');
        setIsButtonDisabled(!allFieldsFilled || cooldown > 0);
    }, [dadosFormulario, cooldown]);

    // Controla o cooldown do botão de cadastro
    useEffect(() => {
        if (cooldown > 0) {
            const timer = setInterval(() => {
                setCooldown(prevCooldown => prevCooldown - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [cooldown]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-white">
            <div className="relative z-20 flex items-center justify-center p-4 md:p-6 w-full">
                <div className="flex flex-col md:flex-row bg-black bg-opacity-90 rounded-lg overflow-hidden w-full md:max-w-5xl mx-auto shadow-2xl z-30" style={{ boxShadow: '0px 10px 30px rgba(0, 0, 0, 0.5)' }}>
                    <div className="flex flex-col justify-center items-center p-4 md:p-6 w-full md:w-3/5">
                        <div className="flex flex-col items-center mb-4 w-full max-w-md mx-auto">
                            <h2 className="text-white text-lg md:text-5xl font-bold mb-4">Cadastro</h2>

                            {/* Exibe a mensagem de erro, se houver */}
                            {errorMessage && (
                                <div className="bg-red-500 text-white px-4 py-2 rounded-md mb-4">
                                    {errorMessage}
                                </div>
                            )}

                            <form className="w-full flex-grow flex flex-col justify-center items-center space-y-2">
                                {Object.keys(dadosFormulario).map((key) => (
                                    <div className="w-full" key={key}>
                                        <label className="block text-white mb-1" htmlFor={key}>
                                            {key.replace(/([A-Z])/g, ' $1').toUpperCase()}
                                        </label>
                                        {(key === 'cep' || key === 'cpf' || key === 'telefone') ? (
                                            <MaskedInput
                                                className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:outline-none"
                                                type="text"
                                                id={key}
                                                value={dadosFormulario[key]}
                                                onChange={handleChange}
                                                mask={key === 'cep' ? cepMask : key === 'cpf' ? cpfMask : telefoneMask}
                                            />
                                        ) : (
                                            <input
                                                className="w-full px-3 py-2 bg-gray-800 text-white rounded focus:outline-none"
                                                type={key === 'dataNascimento' ? 'date' : key === 'senha' ? 'password' : 'text'}
                                                id={key}
                                                value={dadosFormulario[key]}
                                                onChange={handleChange}
                                            />
                                        )}
                                    </div>
                                ))}
                                <button
                                    className={`bg-blue-600 text-white font-bold py-2 px-8 rounded-xl focus:outline-none ${isButtonDisabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'}`}
                                    type="button"
                                    onClick={() => salvarInformacoes(dadosFormulario.senha, dadosFormulario.email, dadosFormulario.nomeCompleto, dadosFormulario.cep, dadosFormulario.telefone, dadosFormulario.cpf, dadosFormulario.dataNascimento)}
                                    disabled={isButtonDisabled}
                                >
                                    {cooldown > 0 ? `Aguarde ${cooldown} segundos` : 'Cadastrar'}
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="relative hidden md:flex items-center justify-center bg-cover bg-center md:w-2/5">
                        <img src={fundoVertical} alt="fundo vertical" className="absolute inset-0 h-full w-full object-cover z-0" />
                        <img src={logo} alt="Kaio Marcio" className="relative h-28 md:h-44 object-contain z-10" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Registro;
