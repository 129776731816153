import React, { useState } from 'react';
import logo from '../../assets/LogoIconBranca.png';
import Lateral from '../../components/lateral/index';
import Capa from './capa.js'; 
import Sobre from './sobre.js'; 
import Local from './local.js';

const Dashboard = () => {
    const [expanded, setExpanded] = useState(null); // Estado para controlar qual seção está expandida

    const handleExpand = (section) => {
        setExpanded(expanded === section ? null : section); // Expande ou recolhe a seção
    };

    return (
        <div className="min-h-screen flex flex-col md:flex-row bg-gray-100">
            <Lateral />
            <main className="flex-1 md:ml-80">
                <div className='flex justify-end'>
                    <img src={logo} alt="Kaio Marcio" className="h-24 md:h-36 bg-sky-900 rounded-ee-3xl rounded-es-3xl mr-40 hidden md:block" />
                </div>
                <div className='pl-8 pr-8 mt-5'>
                    <header className="flex items-center justify-between mb-8">
                        <h1 className="text-5xl font-bold text-sky-900">Página do Evento</h1>
                    </header>
                    <div className='mt-5 mb-10'>
                        <button onClick={() => handleExpand('capa')} className="block w-full text-left">
                            <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                                <h2 className="text-xl font-bold mb-2">Capa</h2>
                                <p className="text-gray-600">Imagem de fundo do seu evento</p>
                            </div>
                        </button>
                        {expanded === 'capa' && (
                            <Capa /> 
                        )}
                        <button onClick={() => handleExpand('sobre')} className="block w-full text-left">
                            <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                                <h2 className="text-xl font-bold mb-2">Sobre o evento</h2>
                                <p className="text-gray-600">Área destinada à informações personalizadas</p>
                            </div>
                        </button>
                        {expanded === 'sobre' && (
                            <Sobre /> // Renderiza o componente Sobre.js quando a seção "Sobre" está expandida
                        )}
                        
                        <button onClick={() => handleExpand('local')} className="block w-full text-left">
                            <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                                <h2 className="text-xl font-bold mb-2">Local do Evento</h2>
                                <p className="text-gray-600">Área destinada às informações sobre o local</p>
                            </div>
                        </button>
                        {expanded === 'local' && (
                            <Local /> // Renderiza o componente Local.js quando a seção "Local" está expandida
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;
