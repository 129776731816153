// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descricao-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .titulo-principal {
    text-align: center;
    color: #333;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .detalhes-container {
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  .detalhes-container h2, .detalhes-container h3, .detalhes-container h4 {
    color: #0056b3;
    margin-bottom: 10px;
  }
  
  .detalhes-container p {
    font-size: 16px;
    color: #555;
  }
  
  .qr-code-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }
  
  .loading-text {
    text-align: center;
    font-size: 18px;
    color: #888;
  }
  .gastar-btn {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #0056b3;
    color: #fff;
    font-size: 18px;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }`, "",{"version":3,"sources":["webpack://./src/pages/gastarIngresso/Descricao.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,wCAAwC;IACxC,gCAAgC;EAClC;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;EACA;IACE,cAAc;IACd,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;EACjB","sourcesContent":[".descricao-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    font-family: 'Arial', sans-serif;\n  }\n  \n  .titulo-principal {\n    text-align: center;\n    color: #333;\n    font-size: 24px;\n    margin-bottom: 20px;\n  }\n  \n  .detalhes-container {\n    padding: 15px;\n    background-color: #fff;\n    border-radius: 8px;\n    border: 1px solid #ddd;\n  }\n  \n  .detalhes-container h2, .detalhes-container h3, .detalhes-container h4 {\n    color: #0056b3;\n    margin-bottom: 10px;\n  }\n  \n  .detalhes-container p {\n    font-size: 16px;\n    color: #555;\n  }\n  \n  .qr-code-container {\n    display: flex;\n    justify-content: center;\n    margin: 20px 0;\n  }\n  \n  .loading-text {\n    text-align: center;\n    font-size: 18px;\n    color: #888;\n  }\n  .gastar-btn {\n    display: block;\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n    background-color: #0056b3;\n    color: #fff;\n    font-size: 18px;\n    text-align: center;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
