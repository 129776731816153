import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import 'react-circular-progressbar/dist/styles.css'; // Estilo do circular progressbar
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import api from '../../components/api/api'; // Certifique-se de que o arquivo da API esteja correto

function Upload() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null); // Guarda o arquivo selecionado
  const [preview, setPreview] = useState(null); // Guarda o preview da imagem
  const [isUploading, setIsUploading] = useState(false); // Estado de upload
  const [progress, setProgress] = useState(0); // Progresso do upload

  // Função para lidar com o upload utilizando a URL pré-assinada
  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Selecione uma imagem antes de prosseguir!');
      return;
    }

    setIsUploading(true);

    try {
      const response = await api.get(`/minioRoutas/generate-presigned-url/${localStorage.getItem('idevento')}`);
      const presignedUrl = response.data.url;
      // Iniciar o upload do arquivo no formato original
      const upload = await fetch(presignedUrl, {
        method: 'PUT',
        body: selectedFile, // Enviando o arquivo no formato original
        headers: {
          'Content-Type': selectedFile.type, // Garantindo o tipo MIME correto
        },
      });

      if (!upload.ok) {
        throw new Error('Erro no upload da imagem.');
      }

      console.log('Upload concluído:', selectedFile.name);
      alert('Imagem enviada com sucesso!');
      navigate('/admin');
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
      alert('Erro ao enviar a imagem.');
    } finally {
      setIsUploading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      setSelectedFile(file);
      setPreview(URL.createObjectURL(file)); // Gerar um preview do arquivo selecionado
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    maxFiles: 1, // Aceitar apenas um arquivo
  });

  const handleDelete = () => {
    setSelectedFile(null);
    setPreview(null);
    setProgress(0);
  };

  return (
    <div className="flex flex-col md:flex-row h-screen w-full">
      {/* Lado esquerdo: Upload de imagem */}
      <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
        <a href="#" onClick={() => navigate(-1)} className="black font-bold text-2xl underline mb-11 ml-8">VOLTAR</a>
        <div className="flex flex-col items-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">CRIAR EVENTO</h2>
          <h1 className="flex text-start font-bold text-xl mb-2">Adicionar Imagem</h1>

          {/* Área de dropzone */}
          {!selectedFile && (
            <div
              {...getRootProps()}
              className="flex items-center justify-center w-full max-w-sm h-48 border-2 border-black rounded-xl cursor-pointer bg-gray-100 hover:bg-gray-200"
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p className="text-center text-gray-600">Solte a imagem aqui...</p>
              ) : (
                <FiPlus size={32} className="text-gray-500" />
              )}
            </div>
          )}

          {/* Preview da imagem selecionada */}
          {preview && (
            <div className="mt-4 relative">
              <img
                src={preview}
                alt="Preview da imagem"
                className="rounded-md shadow-md max-w-full h-auto" // Remove limitação de tamanho
                style={{ maxHeight: '500px' }} // Limita a altura máxima, mas mantém proporções
              />

              {/* Botão para excluir a imagem */}
              <button
                onClick={handleDelete}
                className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full shadow-md"
              >
                <FiTrash2 size={20} />
              </button>
            </div>
          )}

          {/* Botão de upload */}
          <div className="flex justify-center items-center mt-4">
            <button
              onClick={handleUpload}
              type="button"
              className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full mt-4 flex items-center justify-center"
              disabled={!selectedFile || isUploading}
            >
              {isUploading ? "Enviando..." : "PRÓXIMO"}
            </button>
          </div>
        </div>
      </div>

      {/* Lado direito: Imagem de fundo */}
      <div className="hidden md:flex md:w-2/3 relative">
        <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
          <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upload;
