// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    box-sizing: border-box;
}

.search-bar {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    max-width: 270px;
}

.search-bar input {
    flex: 1 1;
    border-radius: 0;
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.search-bar button {
    flex-shrink: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

@media (max-width: 768px) {
    .search-bar input {
        width: calc(100% - 80px); /* Ajuste a largura para a soma da largura total menos a largura do botão */
    }

    .search-bar button {
        width: 89px; /* Define a largura fixa do botão */
    }

    .event-card {
        margin: 0 auto; /* Centraliza as cartas dos eventos */
    }

    .tab-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .tab-buttons button {
        
    }

    
}
`, "",{"version":3,"sources":["webpack://./src/pages/meusingressos/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,8BAA8B;IAC9B,iCAAiC;AACrC;;AAEA;IACI,cAAc;IACd,+BAA+B;IAC/B,kCAAkC;AACtC;;AAEA;IACI;QACI,wBAAwB,EAAE,2EAA2E;IACzG;;IAEA;QACI,WAAW,EAAE,mCAAmC;IACpD;;IAEA;QACI,cAAc,EAAE,qCAAqC;IACzD;;IAEA;QACI,aAAa;QACb,mBAAmB;QACnB,mBAAmB;QACnB,SAAS;IACb;;IAEA;;IAEA;;;AAGJ","sourcesContent":["body {\n    overflow-x: hidden;\n}\n\n.container {\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.search-bar {\n    display: flex;\n    flex-wrap: nowrap;\n    width: 100%;\n    max-width: 270px;\n}\n\n.search-bar input {\n    flex: 1;\n    border-radius: 0;\n    border-top-left-radius: 9999px;\n    border-bottom-left-radius: 9999px;\n}\n\n.search-bar button {\n    flex-shrink: 0;\n    border-top-right-radius: 9999px;\n    border-bottom-right-radius: 9999px;\n}\n\n@media (max-width: 768px) {\n    .search-bar input {\n        width: calc(100% - 80px); /* Ajuste a largura para a soma da largura total menos a largura do botão */\n    }\n\n    .search-bar button {\n        width: 89px; /* Define a largura fixa do botão */\n    }\n\n    .event-card {\n        margin: 0 auto; /* Centraliza as cartas dos eventos */\n    }\n\n    .tab-buttons {\n        display: flex;\n        flex-direction: row;\n        align-items: center;\n        gap: 10px;\n    }\n\n    .tab-buttons button {\n        \n    }\n\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
