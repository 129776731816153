import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header/index";
import Footer from "../../components/footer-main";
import ImageFundo from "../../assets/FOTO-FUNDO-PRINCIPAL.png";
import image from '../../assets/FOTO FUNDO LOGIN.png'
import api from "../../components/api/api";

function Main() {
  const navigate = useNavigate();
  const [showHeader, setShowHeader] = useState(true);

  const [data, setData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    // Função para verificar se o usuário está autenticado (pode ser substituída por sua lógica de autenticação)
    const checkAuth = () => {
      // Exemplo: Verifique se existe um token no localStorage
      const token = localStorage.getItem('token');
      setIsAuthenticated(!!token);
    };

    checkAuth();
  }, []);
  const handleDescription = (id) => {
    if (isAuthenticated) {
      // Se o usuário estiver autenticado, continue para a descrição
      localStorage.setItem("idevento", id);
      navigate("/descricao");
    } else {
      // Se não estiver autenticado, redirecione para a página de login
      navigate("/login");
    }
  };


  const fetchData = async () => {
    try {
      const response = await api.get("/eventos");
      const events = response.data.listeventos;

      // Para cada evento, buscamos a URL da imagem e associamos ao evento
      const updatedEvents = await Promise.all(events.map(async (event) => {
        const imageUrlResponse = await api.get(`/minioRoutas/generate-presigned-url-get/${event.id}`);
        const imageUrl = imageUrlResponse.data.url;
        return { ...event, image: imageUrl }; // adiciona a URL da imagem ao evento
      }));

      // Recupera os eventos ocultados do localStorage
      const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];

      // Filtra os eventos que não estão ocultados
      const visibleEvents = updatedEvents.filter(event => !hiddenEvents.includes(event.id));

      setData(visibleEvents);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setShowHeader(false);
      } else {
        setShowHeader(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    fetchData();
  }
    , []);

  return (
    <div>
      {showHeader && <Header />}

      <section
        className="relative h-screen bg-cover bg-center"
        style={{ backgroundImage: `url(${ImageFundo})` }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="container mx-auto h-full flex flex-col justify-center items-center relative z-10 text-white px-4">

          <div className="text-center mt-16">
            <h1 className="text-5xl sm:text-8xl font-bold mb-4">EVENTOS</h1>
            <p className="mb-8 max-w-xl mx-auto text-center">
            Se você é apaixonado por natação e busca oportunidades para aperfeiçoar suas habilidades, participar de competições ou simplesmente conectar-se com outros entusiastas do esporte, está no lugar certo! Nossa plataforma reúne os principais eventos de natação organizados por Kaio Marcio, um dos maiores nomes da modalidade.
            </p>
            <a href="/meus-ingressos" className="px-6 py-3 bg-blue-900 text-white font-bold rounded-xl">
              MINHAS INSCRIÇÕES
            </a>
          </div>
        </div>
      </section>

      <section className="bg-white py-12">
        <div className="wave-container absolute bottom-0 left-0 w-full overflow-hidden leading-[0] -mb-12 z-20">
          <svg viewBox="0 0 1440 320">
            <path fill="#fff" fillOpacity="1" d="M0,224L48,213.3C96,203,192,181,288,176C384,171,480,181,576,197.3C672,213,768,235,864,245.3C960,256,1056,256,1152,240C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg>
        </div>
        <div className="container mx-auto text-center mb-8">
          <button className="text-xl sm:text-2xl font-bold">
            JOÃO PESSOA-PB{" "}
          </button>
          <span className="mx-8 hidden sm:inline text-xl sm:text-2xl font-bold">
            |
          </span>
          <button className="text-xl sm:text-2xl font-bold">
            {" "}
            BELO HORIZONTE-MG
          </button>
        </div>
        <div className="events-section pt-4 pb-20">
          <div className="flex justify-center">
            <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 px-4 card-grid">
              {data && data.map((event) => (
                <div key={event.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-xl w-full mx-auto event-card" style={{ padding: '20px' }}>
                  <img
                    src={event.image || image}
                    alt={`Evento ${event.id}`}
                    className="w-full h-48 object-cover rounded-t-lg"
                  />
                  <div className="p-4 card-content">
                    <h3 className="font-semibold text-xl mb-2 card-title">{event.title}</h3>
                    <span className="font-medium text-sm">{event.local}</span>
                    <p className="text-gray-600 mb-4">{event.description_shorts}</p>
                    <button onClick={() => handleDescription(event.id)} className="px-12 py-3 bg-[#134A6D] text-white font-bold rounded-md text-center">
                      SAIBA MAIS
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>


      <section className="bg-white w-full h-full flex flex-col items-center justify-center py-20 px-4">
        <h1 className="text-black text-3xl md:text-5xl font-bold mb-8 text-center">
          ACESSE NOSSA LOJA
        </h1>
        <p className="text-black text-center max-w-xl mb-8">
        Equipamentos de alta performance fazem toda a diferença no seu desempenho na piscina. Na Loja Oficial de Kaio Marcio, você encontra tudo o que precisa para elevar seu nível, com a qualidade e confiança de um campeão mundial!

Explore nossa linha exclusiva de óculos de natação, toucas, roupas esportivas e acessórios desenvolvidos especialmente para nadadores de todos os níveis. Cada item é escolhido com cuidado para proporcionar conforto, durabilidade e desempenho, ajudando você a alcançar seus objetivos dentro d'água.

Acesse agora e descubra os produtos favoritos de Kaio Marcio. Nade como um campeão!
        </p>
        <a
          href="https://www.kaiomarcio.com.br"
          target="_blank"
          rel="noreferrer"
          className="bg-blue-950 text-white font-bold py-2 px-4 rounded-lg"
        >
          WWW.KAIOMARCIO.COM.BR
        </a>
      </section>

      <Footer />
    </div>
  );
}

export default Main;
