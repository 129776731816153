import React from 'react';
import { CgMail } from "react-icons/cg";
import { FaPhone } from "react-icons/fa6";
import logo from '../../assets/LogoIconBranca.png';

const Footer = () => {
    return (
        <footer className="bg-black justify-center items-center text-white py-8">
            <div className="container mx-auto flex flex-col md:flex-row justify-around items-center space-y-8 md:space-y-0 md:space-x-8 text-center md:text-left">
                <div className="flex flex-col items-center space-y-4">
                    <div className="flex items-center space-x-2">
                        <FaPhone className='text-xl size-6' />
                        <span>(88) 97345-7698</span>
                    </div>
                    <div className="flex items-center space-x-2">
                        <CgMail className='text-xl size-7' />
                        <span className='font-bold'>kaiomarcio@hotmail.com</span>
                    </div>
                </div>
                <div className="text-center">
                    <img className="h-44 w-auto" src={logo} alt="Logo" />
                </div>
                <div className="max-w-xs text-sm">
                    <p> Se você é apaixonado por natação e busca oportunidades para aperfeiçoar suas habilidades, participar de competições ou simplesmente conectar-se com outros entusiastas do esporte, está no lugar certo! Nossa plataforma reúne os principais eventos de natação organizados por Kaio Marcio.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
