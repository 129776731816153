import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import api from '../../components/api/api';
import axios from 'axios';
import MaskedInput from 'react-text-mask';

const cepMask = [/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

function Endereco() {
    const navigate = useNavigate();
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [bairro, setBairro] = useState('');
    const [localidade, setLocalidade] = useState('');
    const [uf, setUf] = useState('');
    const [ibge, setIbge] = useState('');
    const [ddd, setDdd] = useState('');
    const [siafi, setSiafi] = useState('');

    const handleCepChange = async (e) => {
        const cepValue = e.target.value.replace(/\D/g, '');
        setCep(cepValue);

        if (cepValue.length === 8) {
            try {
                const response = await axios.get(`https://viacep.com.br/ws/${cepValue}/json/`);
                const data = response.data;

                if (data.erro) {
                    alert("CEP não encontrado. Verifique e tente novamente.");
                } else {
                    setLogradouro(data.logradouro);
                    setBairro(data.bairro);
                    setLocalidade(data.localidade);
                    setUf(data.uf);
                    setIbge(data.ibge);
                    setDdd(data.ddd);
                    setSiafi(data.siafi);
                }
            } catch (error) {
                console.error("Erro ao buscar o CEP:", error);
                alert("Erro ao buscar o CEP. Verifique e tente novamente.");
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!localidade || !uf) {
            alert("Por favor, preencha todos os campos.");
            return;
        }

        const token = localStorage.getItem('token');

        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            navigate('/login');
            return;
        }

        const endereco = {
            cep: cep,
            logradouro: logradouro,
            bairro: bairro,
            localidade: localidade,
            uf: uf,
            ibge: ibge,
            ddd: ddd,
            siafi: siafi,
        };

        try {
            const response = await api.post('/endereco', endereco, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201 || response.status === 200) {
                const enderecoId = response.data.id;
                localStorage.setItem('enderecoId', enderecoId);
                navigate('/adicionar-sede');
            } else {
                alert("Erro ao criar endereço! Status: " + response.status);
            }
        } catch (error) {
            console.error('Erro detalhado:', error.response ? error.response.data : error.message);
            alert("Erro ao criar endereço! " + (error.response?.data?.message || error.message));
        }
    };

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
                <div className="flex items-center mb-11 ml-8">
                    <IoMdArrowRoundBack className="text-2xl text-blue-500 size-7" />
                    <button
                        onClick={() => navigate(-1)}
                        className="text-xl text-blue-500 hover:text-blue-700 font-bold text-lg"
                    >
                        VOLTAR
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">ADICIONAR ENDEREÇO</h2>
                    <form onSubmit={handleSubmit} className="w-full max-w-xs space-y-4">
                        <div>
                            <label htmlFor="cep" className="block text-sm font-bold mb-1 ml-4">CEP</label>
                            <MaskedInput
                                mask={cepMask}
                                type="text"
                                id="cep"
                                className="w-full border rounded-full px-3 py-2"
                                value={cep}
                                onChange={handleCepChange}
                                placeholder="Somente números"
                            />
                        </div>
                        <div>
                            <label htmlFor="localidade" className="block text-sm font-bold mb-1 ml-4">CIDADE DO EVENTO</label>
                            <input
                                type="text"
                                id="localidade"
                                className="w-full border rounded-full px-3 py-2"
                                value={localidade}
                                onChange={(e) => setLocalidade(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="uf" className="block text-sm font-bold mb-1 ml-4">UF DO EVENTO</label>
                            <input
                                type="text"
                                id="uf"
                                className="w-full border rounded-full px-3 py-2"
                                value={uf}
                                onChange={(e) => setUf(e.target.value)}
                            />
                        </div>
                        <div className='flex justify-center items-center'>
                            <button type="submit" className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full mt-4 justify-center flex items-center">PRÓXIMO</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Endereco;
