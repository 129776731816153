import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const Modal = ({ show, event, onClose, email, phone, cep, gender }) => {
    const [category, setCategory] = useState(event.name);
    const [userEmail, setUserEmail] = useState(email);
    const [userNum, setUserNum] = useState(phone);

    useEffect(() => {
        setUserEmail(email);
        setUserNum(phone);
    }, [email, phone]);

    if (!show) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 relative max-h-screen overflow-y-auto">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-500 text-3xl font-bold focus:outline-none"
                >
                    <FaTimes className='size-7' />
                </button>

                <div className="space-y-6">
                    <div className="space-y-4">
                        <div>
                            <label className="block text-gray-700 font-medium">Nome</label>
                            <input
                                type="text"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
                            />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className="block text-gray-700 font-medium">Email</label>
                                <input
                                    type="email"
                                    value={userEmail}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
                                />
                            </div>
                            <div>
                                <label className="block text-gray-700 font-medium">Número do usuário</label>
                                <input
                                    type="text"
                                    value={userNum}
                                    onChange={(e) => setUserNum(e.target.value)}
                                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-end">
                        <button
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg focus:outline-none">Voltar</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
