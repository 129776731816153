import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from '../../assets/LogoIconBranca.png';
import Lateral from '../../components/lateral/index';
import Modal from './modal';
import api from '../../components/api/api';
import './style.css';

const Dashboard = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);  // Armazena os ingressos
  const [selectedEvent, setSelectedEvent] = useState(null);  // Controle do modal

  useEffect(() => {
    const eventoId = localStorage.getItem('idevento');

    // Função para buscar os ingressos do evento pelo evento_id
    const fetchIngressos = async () => {
      try {
        const response = await api.get(`/ingressos/eventos/${eventoId}/ingressos`);
        const ingressos = response.data;
        console.log(ingressos);
        const formattedEvents = ingressos.map(ingresso => ({
          id: ingresso.id,
          name: ingresso.titulo,
          value: ingresso.valor,
          capacity: ingresso.qta_max,
          sold: ingresso.participantesNumber,
        }));
        setEvents(formattedEvents);
      } catch (error) {
        console.error('Erro ao buscar ingressos!', error);
      }
    };

    if (eventoId) {
      fetchIngressos();
    } else {
      console.error('Evento ID não encontrado no localStorage');
    }
  }, []);

  const handleEventClick = (event) => {
    console.log('Evento selecionado:', event);
    setSelectedEvent(event);  // Define o evento selecionado para exibir no modal
  };
  
  const handleCloseModal = () => {
    setSelectedEvent(null);  // Fecha o modal
  };

  const handleClick = () => {
    navigate(`/criar-ingresso`);
  };

  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gray-100">
      <Lateral />
      <main className="flex-1 md:ml-80">
        <div className='flex justify-center md:justify-end'>
          <img 
            src={logo} 
            alt="Kaio Marcio" 
            className="h-20 md:h-36 bg-sky-900 rounded-ee-3xl rounded-es-3xl mr-0 md:mr-40 hidden md:block" 
          />
        </div>
        <div className='pl-4 pr-4 md:pl-8 md:pr-8 mt-5'>
          <header className="flex flex-col md:flex-row items-center justify-between mb-8 text-center md:text-left">
            <h1 className="text-3xl md:text-5xl font-bold text-sky-900">Ingressos</h1>
          </header>
          <div className="space-y-4 w-full mx-auto ">
            {events.map((event, index) => (
              <div
                key={event.id}
                className="bg-white rounded-lg shadow-md p-6 flex flex-col md:flex-row items-center justify-between cursor-pointer"
                onClick={() => handleEventClick(event)}
              >
                <div className="flex-1 md:mr-4 text-center md:text-left">
                  <h2 className="text-lg font-bold">{event.name}</h2>
                  <p className="text-gray-600">VALOR R${event.value},00</p>
                </div>
                <div className="flex items-center justify-between border-t-2 md:border-t-0 md:border-l-2 pt-4 md:pt-0 md:pl-4 border-gray-500">
                  <div className="flex items-center justify-center">
                    <p className="text-3xl font-bold">{event.sold}</p>
                    <span className="text-xl mx-2">/</span>
                    <p className="text-4xl font-bold">{event.capacity}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-center md:justify-start">
          <button
            type="button"
            className="w-48 h-12 md:w-50 md:h-12 md:ml-7 bg-[#134A6D] text-white text-xl md:text-xl font-bold rounded-full mt-10 flex items-center justify-center"
            onClick={handleClick}
          >
            Criar Ingresso  
          </button>
        </div>
      </main>
      {selectedEvent && (
        <Modal
        show={!!selectedEvent}
        eventId={selectedEvent?.id}
        onClose={() => setSelectedEvent(null)}
      />
      )}
    </div>
  );
};

export default Dashboard;
