import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Footer from '../../components/footer-main';
import api from '../../components/api/api';
import './style.css';

function Index() {
    const [activeTab, setActiveTab] = useState('ATIVO');
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                const response = await api.get(`/ingressos/myIngresso`, {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                });

                const events = response.data;

                // Para cada evento, buscamos a URL da imagem e associamos ao evento
                const updatedEvents = await Promise.all(events.map(async (event) => {
                    try {
                        const imageUrlResponse = await api.get(`/minioRoutas/generate-presigned-url-get/${event.ingresso.evento.id}`);
                        const imageUrl = imageUrlResponse.data.url;
                        console.log(imageUrl);
                        return { ...event, image: imageUrl }; // adiciona a URL da imagem ao evento
                    } catch (error) {
                        console.error("Erro ao buscar a imagem:", error);
                        return { ...event, image: 'default-image.png' }; // Define uma imagem padrão em caso de erro
                    }
                }));

                setData(updatedEvents);
            } catch (error) {
                console.error("Erro ao buscar eventos ou ingressos:", error);
            }
        };
        fetchData();
    }, []);

    const filteredData = data//.filter(event => event.status === activeTab);

    return (
        <div>
            <div className='mt-5'>
                <a href="#" onClick={() => navigate("/")} className="black font-bold text-2xl mt-20 underline mb-4 ml-7 ">VOLTAR</a>
            </div>

            <div className="mt-6 w-full h-auto max-w-6xl mx-auto">
                <div className="mt-5 w-full max-w-4xl mx-auto">
                    <div className='flex flex-col md:flex-row justify-between items-center w-full'>
                        <h1 className="text-black text-2xl md:text-3xl font-bold mb-4 md:mb-0">MEUS INGRESSOS</h1>
                        <div className="search-bar mb-8 md:mb-0">
                            <input type="text" placeholder="EVENTO" className="border py-2 px-4" />
                            <button className="bg-black text-white font-bold py-2 px-4 ">BUSCAR</button>
                        </div>
                    </div>

                    <div className="flex mb-4 mt-8 justify-center tab-buttons">
                        <button
                            className={`text-xl sm:text-xl font-bold ${activeTab === 'ATIVO' ? 'underline' : ''}`}
                            onClick={() => setActiveTab('ATIVO')}
                        >
                            ATIVOS
                        </button>
                        <span className="mx-8 sm:inline text-xl sm:text-xl font-bold">|</span>
                        <button
                            className={`text-xl sm:text-xl font-bold ${activeTab === 'ENCERRADO' ? 'underline' : ''}`}
                            onClick={() => setActiveTab('ENCERRADO')}
                        >
                            ENCERRADOS
                        </button>
                    </div>
                </div>

                <div className="flex justify-center mt-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
                        {filteredData.length > 0 ? (
                            filteredData.map((event) => (
                                <div key={event.transactionId} className="bg-gray-100 rounded-lg overflow-hidden shadow-xl w-full mx-auto event-card">
                                    <img
                                        src={event.image || 'default-image.png'}  // Exibe a imagem do evento ou uma imagem padrão
                                        alt={`Evento ${event.ingresso.titulo}`}
                                        className="w-full h-48 object-cover rounded-t-lg"
                                    />
                                    <div className="p-4">
                                        <div className="flex justify-between items-center mb-2">
                                            <h3 className="font-semibold text-xl mb-2">{event.ingresso.titulo}</h3>
                                            <a href={`/descricao/${event.id}`} className="px-2 py-1 mb-2 bg-[#134A6D] text-white font-bold rounded-md text-center">
                                                VER INGRESSO
                                            </a>
                                        </div>
                                        <p className="font-medium text-sm">{event.ingresso.evento.title}</p>
                                        <p className="text-gray-600">{event.ingresso.evento.description_shorts}</p>
                                        <p className="text-gray-600 mb-4">Valor: R$ {event.ingresso.valor}</p>
                                        <p className="text-gray-600">Início: {new Date(event.ingresso.evento.data_inicio).toLocaleDateString('pt-BR')}</p>
                                        <p className="text-gray-600">Fim: {new Date(event.ingresso.evento.data_fim).toLocaleDateString('pt-BR')}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center text-gray-600">Nenhum ingresso encontrado.</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="bg-white w-full h-full flex flex-col items-center justify-center pt-5 pb-20 mt-6">
                <h1 className="text-black text-3xl md:text-5xl font-bold mb-4 mt-8">ACESSE NOSSA LOJA</h1>
                <p className="text-black text-center max-w-xl mb-8 px-4">
                Explore um mundo de eventos exclusivos, experiências únicas e produtos feitos especialmente para você. Dos eventos mais recentes acontecendo na sua cidade a uma seleção de produtos em nossa loja, buscamos proporcionar momentos inesquecíveis. Descubra nossa variedade de ofertas, cuidadosamente elaboradas para trazer alegria e emoção ao seu dia a dia. Seja para encontrar sua próxima aventura ou o presente perfeito, temos algo para todos. Mergulhe e faça de cada dia uma experiência extraordinária.
                </p>
                <a href="https://www.kaiomarcio.com.br" target="_blank" rel="noopener noreferrer" className="bg-black text-white font-bold py-2 px-2 rounded-lg">
                    WWW.KAIOMARCIO.COM.BR
                </a>
            </div>

            <Footer />
        </div>
    );
}

export default Index;
