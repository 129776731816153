import React, { useEffect, useState } from 'react';
import api from '../../components/api/api'; // Sua instância da API
import img from './../../assets/FOTO-FUNDO-PRINCIPAL.png'; // Certifique-se de que o caminho para a imagem esteja correto

const Expansao = () => {
    const [imageData, setImageData] = useState(img); // Inicialmente com a imagem padrão
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false); // Estado para indicar se o upload está em andamento
    const [fileName, setFileName] = useState('Nenhum arquivo selecionado'); // Nome do arquivo selecionado

    useEffect(() => {
        // Código para buscar a imagem da API e atualizar a imagem no estado
        const fetchImageUrl = async () => {
            try {
                const response = await api.get(`/minioRoutas/generate-presigned-url-get/${localStorage.getItem('idevento')}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                setImageData(response.data.url); // Atualiza o estado com a URL da imagem
            } catch (error) {
                console.error('Erro ao buscar a imagem!', error);
            }
        };

        fetchImageUrl();
    }, []);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileName(file ? file.name : 'Nenhum arquivo selecionado');
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            alert('Selecione uma imagem antes de prosseguir!');
            return;
        }

        setIsUploading(true);

        try {
            const response = await api.get(`/minioRoutas/generate-presigned-url/${localStorage.getItem('idevento')}`);
            const presignedUrl = response.data.url;
            // Iniciar o upload do arquivo no formato original
            const upload = await fetch(presignedUrl, {
                method: 'PUT',
                body: selectedFile, // Enviando o arquivo no formato original
                headers: {
                    'Content-Type': selectedFile.type, // Garantindo o tipo MIME correto
                },
            });

            if (!upload.ok) {
                throw new Error('Erro no upload da imagem.');
            }

            console.log('Upload concluído:', selectedFile.name);
            alert('Imagem enviada com sucesso!');
            setModalIsOpen(false);
        } catch (error) {
            console.error('Erro ao fazer upload:', error);
            alert('Erro ao enviar a imagem.', error);
        } finally {
            setIsUploading(false);
        }
    };

    const handleRemoveImage = () => {
        setImageData(img); // Resetar para a imagem padrão
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-4 mb-4">
            <h2 className="text-xl font-bold mb-2">Capa do Evento</h2>
            <p className="text-gray-600">Imagem de fundo do seu evento</p>
            <div className="mt-4">
                {imageData ? (
                    <img
                        src={imageData} // Aqui usamos o dado da imagem que foi carregado da API
                        alt="Capa do Evento"
                        className="w-full h-auto"
                        style={{ maxHeight: '200px', maxWidth: '400px' }}
                    />
                ) : (
                    <p>Carregando imagem...</p>
                )}
            </div>
            <div className="flex justify-start mt-4">
                <button
                    className="bg-green-500 hover:bg-green-600 transition duration-300 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={() => setModalIsOpen(true)}
                >
                    Substituir imagem
                </button>
            </div>

            {/* Modal para substituir a imagem */}
            {modalIsOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-full sm:w-1/3 transform transition-transform duration-500 ease-in-out scale-95">
                        <h2 className="text-2xl font-bold mb-4">Substituir Imagem</h2>

                        {/* Botão estilizado para escolher arquivo */}
                        <div className="mb-4">
                            <label className="bg-blue-500 hover:bg-blue-600 transition duration-300 text-white font-bold py-2 px-4 rounded cursor-pointer">
                                Escolher Arquivo
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    className="hidden" // Esconde o input original
                                />
                            </label>
                            <span className="ml-4 text-gray-600">{fileName}</span>
                        </div>

                        {isUploading ? (
                            <p className="text-blue-500">Enviando imagem...</p>
                        ) : (
                            <div className="flex justify-end">
                                <button
                                    className="bg-blue-500 hover:bg-blue-600 transition duration-300 text-white font-bold py-2 px-4 rounded mr-2"
                                    onClick={handleUpload}
                                >
                                    Enviar
                                </button>
                                <button
                                    className="bg-gray-500 hover:bg-gray-600 transition duration-300 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setModalIsOpen(false)}
                                >
                                    Cancelar
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Expansao;
