import React, { useState, useEffect } from 'react';
import api from '../../components/api/api';
import { useNavigate } from "react-router-dom";
import Footer from '../../components/footer-main';
import logo from "../../assets/LogoIconBranca.png";
import image from '../../assets/FOTO FUNDO LOGIN.png';
import './style.css';

function Index() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    function handleClick() {
        navigate('/criar-endereco');
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await api.get('/eventos');
                const events = response.data.listeventos;

                const updatedEvents = await Promise.all(events.map(async (event) => {
                    const imageUrlResponse = await api.get(`/minioRoutas/generate-presigned-url-get/${event.id}`);
                    const imageUrl = imageUrlResponse.data.url;
                    return { ...event, image: imageUrl };
                }));

                // Recupera os eventos ocultados do localStorage
                const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];

                // Filtra os eventos que não estão na lista de ocultados
                const visibleEvents = updatedEvents.filter(event => !hiddenEvents.includes(event.id));

                setData(visibleEvents);
                if (visibleEvents.length === 0) {
                    setError('Não há eventos disponíveis no momento.');
                }
            } catch (error) {
                setError('Erro ao carregar os eventos. Tente novamente mais tarde.');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleEventClick = (id) => {
        localStorage.setItem("idevento", id);
        navigate(`/status`);
    };

    const handleDeleteEvent = (id) => {
        // Filtra a lista de eventos para remover o evento com o ID correspondente
        const updatedData = data.filter(event => event.id !== id);
        setData(updatedData); // Atualiza a lista de eventos

        // Armazena o ID do evento excluído no localStorage
        const hiddenEvents = JSON.parse(localStorage.getItem('hiddenEvents')) || [];
        hiddenEvents.push(id);
        localStorage.setItem('hiddenEvents', JSON.stringify(hiddenEvents));
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="events-container flex-grow">
                <div className="flex justify-end">
                    <img
                        src={logo}
                        alt="Kaio Marcio"
                        className="h-24 md:h-36 ml-80 bg-sky-900 rounded-ee-3xl rounded-es-3xl mr-40 hidden md:block"
                    />
                </div>
                <div className="flex justify-center items-center">
                    <h2 className="name-cell text-3xl font-bold justify-start text-sky-900">MEUS EVENTOS</h2>
                </div>

                {error && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-4">
                        {error}
                    </div>
                )}

                {isLoading ? (
                    <div className="flex justify-center items-center mt-10">
                        <div className="text-blue-500 font-bold text-lg">Carregando eventos...</div>
                    </div>
                ) : (
                    <div className="flex flex-col items-center w-full mt-10">
                        {data.length > 0 ? (
                            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full px-4 card-grid">
                                {data.map((event) => (
                                    <div key={event.id} className="bg-gray-100 rounded-lg overflow-hidden shadow-md card">
                                        <img
                                            src={event.image || image}
                                            alt={`Evento ${event.id}`}
                                            className="w-full h-40 object-cover card-image"
                                        />
                                        <div className="p-4 card-content">
                                            <h3 className="font-semibold text-xl mb-2 card-title">{event.title}</h3>
                                            <span className="font-medium text-sm">{event.local}</span>
                                            <p className="text-gray-600 mb-4 card-description">{event.description_shorts}</p>
                                            <button
                                                onClick={() => handleEventClick(event.id)}
                                                className="px-7 py-3 bg-[#134A6D] hover:bg-[#0d3348] transition-colors duration-300 text-white font-bold rounded-lg text-center card-button"
                                            >
                                                PAINEL DO EVENTO
                                            </button>
                                            {/* Botão de excluir evento */}
                                            <button
                                                onClick={() => handleDeleteEvent(event.id)}
                                                className="px-2 py-2 bg-red-600 hover:bg-red-700 transition-colors duration-300 text-white font-bold rounded-lg text-center card-button mt-2"
                                            >
                                                Excluir Evento
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex flex-col items-center mt-10">
                                <p className="text-gray-500">Nenhum evento disponível.</p>
                            </div>
                        )}
                    </div>
                )}

                <div className="flex items-center justify-center">
                    <button
                        type="submit"
                        className="w-60 h-20 bg-[#134A6D] text-white text-3xl font-bold rounded-full mt-10 flex items-center justify-center m-10"
                        onClick={handleClick}
                    >
                        Criar Evento
                    </button>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Index;
