import React from 'react';
import logo from "../../assets/LogoIconBranca.png";
import fundo from "../../assets/FOTO FUNDO LOGIN.png";

function NovaSenha() {
    return (
        <div className="relative h-screen flex items-center justify-center">
            <img src={fundo} alt="background" className="absolute inset-0 w-full h-full object-cover z-0" />
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center z-10">
                <div className="flex flex-col md:flex-row bg-black bg-opacity-90 rounded-lg overflow-hidden w-11/12 max-w-md md:max-w-4xl h-auto md:h-3/4 mx-auto">
                    <div className="relative hidden md:flex items-center justify-center bg-cover bg-center md:w-1/2">
                        <img src={fundo} alt="fundo vertical" className="absolute inset-0 h-full w-full object-cover z-0" />
                        <img src={logo} alt="Kaio Marcio" className="relative h-24 md:h-36 object-contain z-10" />
                    </div>
                    <div className="flex flex-col justify-center items-center p-4 md:p-10 w-full md:w-1/2">
                        <h2 className="text-white text-xl md:text-2xl font-bold mb-4">NOVA SENHA</h2>
                        <form className="w-full flex-grow flex flex-col justify-center items-center">
                            <div className="mb-4 w-full">
                                <label className="block text-white mb-1" htmlFor="newPassword">DIGITE AQUI SUA SENHA</label>
                                <input className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none" type="password" id="newPassword" />
                            </div>
                            <div className="mb-4 w-full">
                                <label className="block text-white mb-1" htmlFor="confirmPassword">CONFIRME A NOVA SENHA</label>
                                <input className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none" type="password" id="confirmPassword" />
                            </div>
                            <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 md:px-8 rounded-xl focus:outline-none" type="button">ENVIAR</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NovaSenha;
