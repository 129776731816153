import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import Main from './pages/home/main';
import Login from './pages/login/Login';
import Cadastro from './pages/register';
import Eventos from './pages/events/events';
import RecuperarSenha from './pages/recoverPassword/recover';
import NovaSenha from './pages/newPassword/new';
import Descricao from './pages/description/description';
import Gastar from './pages/gastarIngresso/index.js';
import Conta from './pages/account/account';
import Deposit from './pages/deposito/index.jsx';
import Ingressos from './pages/meusingressos/index.js';
import Admin from './pages/adm-eventos/index.js';
import AdmAcompanhamento from './pages/adm-acompanhamento/index.js';
import AdmPagamentos from './pages/adm-pagamentos/index.js';
import CriarIngresso from './pages/adm-pagamentos/criar-ingresso.js';
import AdmInscritos from './pages/adm-inscritos/index.js';
import AdmPaginaEventos from './pages/adm-pagina-eventos/index.js';
import AdmCriarEvento from './pages/adm-criar-evento/index.js';
import AdmCriarUpload from './pages/adm-criar-evento/upload.js';
import AdmCriarEndereco from './pages/adm-criar-evento/endereco.js';
import AdmCriarSede from './pages/adm-criar-evento/criar-sede.js';
import AdmAddSede from './pages/adm-criar-evento/add-sede.js';
import VerificaPago from './pages/verifica-pago/index.js';
import DescricaoDetalhes from './pages/DescricaoDetalhes/index.js';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import NotFound from './pages/NotFound/index.js';

const router = createBrowserRouter([
  { path: "/", element: <Main /> },
  { path: "/login", element: <Login /> },
  { path: "/cadastro", element: <Cadastro /> },
  { path: "/eventos", element: <Eventos /> },
  { path: "/recuperar-senha", element: <RecuperarSenha /> },
  { path: "/nova-senha", element: <NovaSenha /> },
  { path: "/descricao", element: <Descricao /> },
  { path: "/minha-conta", element: <Conta /> },
  { path: "/deposito", element: <Deposit /> },
  { path: "/descricao/:id", element: <DescricaoDetalhes /> },
  { path: "/meus-ingressos", element: <Ingressos /> },
  { path: "/admin", element: <Admin /> },
  { path: "/status", element: <AdmAcompanhamento /> },
  { path: "/pagamentos", element: <AdmPagamentos /> },
  { path: "/criar-ingresso", element: <CriarIngresso /> },
  { path: "/inscritos", element: <AdmInscritos /> },
  { path: "/pagina-evento", element: <AdmPaginaEventos /> },
  { path: "/criar-evento", element: <AdmCriarEvento /> },
  { path: "/criar-upload", element: <AdmCriarUpload /> },
  { path: "/criar-endereco", element: <AdmCriarEndereco /> },
  { path: "/criar-sede", element: <AdmCriarSede /> },
  { path: "/adicionar-sede", element: <AdmAddSede /> },
  {path: "/verifica-pago", element: <VerificaPago />},
  {path: "/gastar-ingresso/:id", element: <Gastar />},
  { path: "*", element: <NotFound /> },
]);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
