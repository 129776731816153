import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import logo from "../../assets/LogoIconBranca.png";
import Perfil from '../../assets/perfil_branco.png';
import fundo from "../../assets/FOTO FUNDO LOGIN.png";
import fundoVertical from '../../assets/FOTOS LATEERAIS (3).png';
import api from '../../components/api/api';

function Login() {
    const navigate = useNavigate();
    const [emailOrCpf, setEmailOrCpf] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(''); // Estado para armazenar mensagens de erro

    const handleLogin = async (event) => {
        event.preventDefault();

        // Verificação básica de campos vazios
        if (!emailOrCpf || !password) {
            setErrorMessage("Por favor, preencha todos os campos.");
            return;
        }

        try {
            const response = await api.post("users/login", {
                email: emailOrCpf,
                password: password,
            });

            console.log(response.data);
            const { token } = response.data;

            // Armazena o token no localStorage
            localStorage.setItem("token", token);

            // Redireciona para a página inicial
            navigate("/");
        } catch (error) {
            console.error("Error logging in:", error);

            // Tratamento de erros de login
            if (error.response) {
                if (error.response.status === 401) {
                    setErrorMessage("E-mail ou senha inválidos.");
                } else {
                    setErrorMessage("Erro ao tentar fazer login. Tente novamente mais tarde.");
                }
            } else {
                setErrorMessage("Erro na conexão. Verifique sua internet e tente novamente.");
            }
        }
    };

    return (
        <div className="relative h-screen flex items-center justify-center">
            <img src={fundo} alt="background" className="absolute inset-0 w-full h-full object-cover z-0" />
            <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center z-10">
                <div className="flex flex-col md:flex-row bg-black bg-opacity-90 rounded-lg overflow-hidden w-11/12 max-w-md md:max-w-4xl h-auto md:h-3/4 mx-auto">
                    <div className="relative hidden md:flex items-center justify-center bg-cover bg-center md:w-1/3">
                        <img src={fundoVertical} alt="fundo vertical" className="absolute inset-0 h-full w-full object-cover z-0" />
                        <img src={logo} alt="Kaio Marcio" className="relative h-24 md:h-36 object-contain z-10" />
                    </div>
                    <div className="flex flex-col justify-center items-center p-4 md:p-10 w-full md:w-2/3">
                        <div className="flex flex-col items-center mb-6 w-full max-w-md mx-auto">
                            <div className="mb-4">
                                <img className="h-12 w-12 md:h-15 md:w-15" src={Perfil} alt="Perfil" />
                            </div>
                            <h2 className="text-white text-xl md:text-2xl font-bold mb-4">LOGIN</h2>

                            {/* Exibe a mensagem de erro, se houver */}
                            {errorMessage && (
                                <div className="bg-red-500 text-white px-4 py-2 rounded-md mb-4">
                                    {errorMessage}
                                </div>
                            )}

                            <form className="w-full flex-grow flex flex-col justify-center items-center" onSubmit={handleLogin}>
                                <div className="mb-4 w-full">
                                    <label className="block text-white mb-1" htmlFor="emailOrCpf">EMAIL / CPF</label>
                                    <input
                                        className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none"
                                        type="text"
                                        id="emailOrCpf"
                                        value={emailOrCpf}
                                        onChange={(e) => setEmailOrCpf(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4 w-full">
                                    <label className="block text-white mb-1" htmlFor="password">SENHA</label>
                                    <input
                                        className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none"
                                        type="password"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="flex justify-between text-sm text-white mb-4 w-full">
                                    <button
                                        className="hover:underline bg-transparent border-none p-0"
                                        onClick={() => navigate('/cadastro')}
                                        type='button'
                                    >
                                        FAZER CADASTRO
                                    </button>
                                    <button
                                        className="hover:underline bg-transparent border-none p-0"
                                        onClick={() => navigate('/recuperar-senha')}
                                        type='button'
                                    >
                                        RECUPERAR SENHA
                                    </button>
                                </div>
                                <button
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 md:px-8 rounded-xl focus:outline-none"
                                    type="submit"
                                >
                                    LOGIN
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
