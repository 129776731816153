// modal.js
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import api from '../../components/api/api';

const Modal = ({ show, eventId, onClose }) => {
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [value, setValue] = useState('');
  const [maxQuantity, setMaxQuantity] = useState('');
  const [maxQuantityPerPerson, setMaxQuantityPerPerson] = useState('');
  const [isFree, setIsFree] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  useEffect(() => {
    if (eventId) {
      const fetchEvent = async () => {
        try {
          const token = localStorage.getItem('token');
          if (!token) {
            alert("Token de autenticação não encontrado.");
            return;
          }
  
          const response = await api.get(`/ingressos/${eventId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
  
          if (response.status === 200) {
            const eventData = response.data;
            console.log('Dados do ingresso:', eventData);
  
            // Converta as datas para o formato YYYY-MM-DD
            const formattedStartDate = new Date(eventData.data_inicio).toISOString().split('T')[0];
            const formattedEndDate = new Date(eventData.data_fim).toISOString().split('T')[0];
  
            setName(eventData.nome || '');
            setTitle(eventData.titulo || '');
            setValue(eventData.valor || '');
            setMaxQuantity(eventData.qta_max || '');
            setMaxQuantityPerPerson(eventData.qta_max_por_pess || '');
          
            setStartDate(formattedStartDate || '');
            setEndDate(formattedEndDate || '');
          } else {
            alert(`Erro ao carregar dados do ingresso! Status: ${response.status}`);
          }
        } catch (error) {
          console.error('Erro ao carregar dados do ingresso:', error);
          alert('Erro ao carregar dados do ingresso!');
        }
      };
  
      fetchEvent();
    }
  }, [eventId]);
  

  const handleSave = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      alert("Token de autenticação não encontrado.");
      return;
    }
  
    const updatedEvent = {
      nome: name,
      titulo: title,
      valor: isFree ? 0 : parseFloat(value),
      qta_max: parseInt(maxQuantity),
      qta_max_por_pess: parseInt(maxQuantityPerPerson),
    
      data_inicio: new Date(startDate).toISOString(),  // Converte para o formato ISO
      data_fim: new Date(endDate).toISOString(),      // Converte para o formato ISO
    };
  
    try {
      const response = await api.put(`/ingressos/${eventId}`, updatedEvent, {
        headers: { Authorization: `Bearer ${token}` }
      });
      if (response.status === 200) {
        alert('Ingresso atualizado com sucesso!');
        onClose();  // Fecha o modal após salvar
      } else {
        alert(`Erro ao atualizar ingresso! Status: ${response.status}`);
      }
    } catch (error) {
      console.error('Erro ao atualizar ingresso:', error);
      alert('Erro ao atualizar ingresso!');
    }
  };
  

  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 relative max-h-screen overflow-y-auto">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 text-3xl font-bold focus:outline-none">
          <FaTimes />
        </button>
        <div className="space-y-6">
          {/* <div className="mb-6">
            <label className="block text-gray-700 font-medium">Nome</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
            />
          </div> */}

          <div className="mb-6">
            <label className="block text-gray-700 font-medium">Título</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-medium">Valor</label>
            <div className="flex items-center">
              <span className="bg-gray-200 p-2 rounded-l-lg border">R$</span>
              <input
                type="number"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                className="w-full p-2 border rounded-r-lg focus:outline-none focus:border-sky-900"
                placeholder="0,00"
                disabled={isFree}
              />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-medium">Quantidade Máxima</label>
            <input
              type="number"
              value={maxQuantity}
              onChange={(e) => setMaxQuantity(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
              placeholder="Ilimitado"
            />
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 font-medium">Quantidade Máxima por Pessoa</label>
            <input
              type="number"
              value={maxQuantityPerPerson}
              onChange={(e) => setMaxQuantityPerPerson(e.target.value)}
              className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
              placeholder="Ilimitado"
            />
          </div>


          <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-gray-700 font-medium">Válido de</label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium">Válido até</label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full p-2 border rounded-lg focus:outline-none focus:border-sky-900"
              />
            </div>
          </div>

          <div className="flex justify-end space-x-4 mt-6">
            <button
              onClick={handleSave}
              className="px-4 py-2 bg-sky-900 text-white rounded-lg focus:outline-none hover:bg-sky-700"
            >
              Salvar
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg focus:outline-none hover:bg-gray-400"
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
