import React, { useState, useEffect } from 'react';
import Lateral from '../../components/lateral';
import Logo from '../../assets/LogoIconBranca.png';
import Modal from './modal';
import ModalAdd from './modalAdd';
import api from '../../components/api/api';

const App = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Adicionado para armazenar o total de páginas
    const [showAddModal, setShowAddModal] = useState(false);

    const [eventId, setEventId] = useState(localStorage.getItem('idevento') || '');

    const fetchUsers = async (id, token, page = 1, search = '') => {
        try {
            const response = await api.get(`/eventos/participantes/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    page: page,
                    search: search
                },
            });

            const allUserEvento = response.data?.usuarios || [];
            setUsers(allUserEvento); // Armazena os usuários no estado
            setTotalPages(response.data?.totalPages || 1); // Atualiza o total de páginas
            setCurrentPage(response.data?.currentPage || 1); // Atualiza a página atual
            setLoading(false);
        } catch (error) {
            console.error("Erro ao buscar os inscritos:", error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            return;
        }

        if (eventId) {
            fetchUsers(eventId, token, currentPage, searchTerm);
        } else {
            alert("ID do evento não encontrado no localStorage.");
        }
    }, [eventId, currentPage, searchTerm]); // Adicionado currentPage e searchTerm para refazer a busca quando mudarem

    const openModal = (user) => {
        setSelectedUser(user);
    };

    const closeModal = () => {
        setSelectedUser(null);
    };

    // Função para alterar o valor de busca
    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reseta para a primeira página ao realizar uma nova busca
    };

    // Função para abrir o modal de adicionar pessoas
    const openAddModal = () => {
        setShowAddModal(true);
    };

    // Função para fechar o modal de adicionar pessoas
    const closeAddModal = () => {
        setShowAddModal(false);
    };

    // Função para lidar com o envio de dados do formulário de adição
    const handleAddSubmit = (formData) => {
        console.log("Dados do novo participante:", formData);
        // Aqui você pode adicionar a lógica para enviar os dados para o backend ou API
    };

    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            <Lateral />
            <div className="flex flex-col w-full">
                <div className='flex justify-center md:justify-end'>
                    <img
                        src={Logo}
                        alt="Kaio Marcio"
                        className="h-16 md:h-36  md:ml-80 md:mr-40 bg-sky-900 rounded-ee-3xl rounded-es-3xl hidden md:block"
                    />
                </div>

                <div className="px-4 md:pl-80 mt-4 md:mt-0">
                    <div className="flex flex-col items-start">
                        <h1 className="text-3xl md:text-5xl font-bold text-sky-900 mb-4 md:mb-8">INSCRITOS</h1>
                        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4 w-full">
                            <input
                                type="text"
                                placeholder="Busque por nome ou e-mail"
                                className="p-2 border rounded w-full md:w-96"
                                value={searchTerm}
                                onChange={handleSearchTermChange}
                            />
                            <button
                                className="bg-sky-900 text-white px-4 py-2 rounded w-full md:w-auto"
                                onClick={openAddModal}  // Abrir modal ao clicar
                            >
                                Adicionar pessoas
                            </button>
                        </div>
                    </div>

                    {loading ? (
                        <p className="text-center">Carregando...</p>
                    ) : (
                        <div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                                {users.length > 0 ? (
                                    users.map((userObj, index) => {
                                        const user = userObj.user;
                                        return (
                                            <div
                                                key={index}
                                                className="bg-white p-4 rounded shadow-md cursor-pointer hover:bg-gray-100 transition duration-300"
                                                onClick={() => openModal(user)}
                                            >
                                                <div className="flex items-center space-x-4">
                                                    <div className="bg-sky-900 text-white rounded-full w-10 h-10 flex items-center justify-center">
                                                        {user.name.split(' ').map(word => word[0]).join('')}
                                                    </div>
                                                    <div>
                                                        <h2 className="font-semibold">{user.name}</h2>
                                                        <p className="text-gray-500">{user.email}</p>
                                                        <p className="text-sm text-gray-500">Ingressos: {userObj.qtaIngressos}</p> {/* Exibe a quantidade de ingressos */}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <p className="text-center col-span-1 sm:col-span-2 lg:col-span-3">Nenhum usuário encontrado.</p>
                                )}
                            </div>

                            {/* Paginação */}
                            <div className="flex justify-center gap-10 items-center mt-10">
                                <button
                                    className={`px-4 py-2 rounded bg-sky-900 text-white ${currentPage <= 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                    disabled={currentPage <= 1}
                                >
                                    Anterior
                                </button>
                                <p>Página {currentPage} de {totalPages}</p>
                                <button
                                    className={`px-4 py-2 rounded bg-sky-900 text-white ${currentPage >= totalPages ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                    disabled={currentPage >= totalPages}
                                >
                                    Próximo
                                </button>
                            </div>
                        </div>
                    )}
                </div>

                {selectedUser && (
                    <Modal
                        show={true}
                        event={selectedUser}
                        onClose={closeModal}
                        email={selectedUser.email}
                        phone={selectedUser.phone}
                    />
                )}

                {/* Modal para adicionar pessoas */}
                <ModalAdd
                    show={showAddModal}
                    onClose={closeAddModal}
                    onSubmit={handleAddSubmit}
                />
            </div>
        </div>
    );
}

export default App;
