import React from 'react';
import { Link } from 'react-router-dom';
import IMG from '../../assets/404.png'; // Certifique-se de que o caminho está correto

function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
      <div className="text-center">
        <div className="flex justify-center items-center mb-8">
          <img src={IMG} alt="404 - Página Não Encontrada" className="w-64 h-auto" />
        </div>
        <h1 className="text-4xl font-semibold text-gray-800">Página Não Encontrada</h1>
        <p className="text-gray-600 mt-4">
          Desculpe, a página que você está procurando não existe. Se você acha que algo está quebrado, relate um problema.
        </p>
        <div className="mt-6 flex justify-center space-x-4">
          <Link to="/" className="px-4 py-2 bg-blue-600 text-white rounded-lg shadow hover:bg-blue-700">
            Pagina Inicial
          </Link>
         
        </div>
      </div>
    </div>
  );
}

export default NotFound;
