import React, { useState, useEffect } from "react";
import { IoIosHome } from "react-icons/io";
import { FaUserPlus, FaTicketAlt, FaBars, FaTimes } from "react-icons/fa";
import { LuCalendarCheck } from "react-icons/lu";
import { GiProgression } from "react-icons/gi";

function Lateral() {
  const [isOpen, setIsOpen] = useState(false); // State to control the sidebar visibility

  // Function to toggle the sidebar
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Disable body scroll when the menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isOpen]);

  return (
    <>
      {/* Hamburger Button */}
      <button
        className="md:hidden text-3xl p-4 focus:outline-none z-50 relative"
        onClick={toggleMenu}
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <aside
        className={`${isOpen ? "translate-x-0" : "-translate-x-full"
          } w-full md:w-72 flex flex-col md:fixed h-full shadow-2xl md:justify-center items-center transform transition-transform duration-300 ease-in-out md:translate-x-0 bg-white absolute z-40`}
      >
        <nav className="flex-1 px-4 py-6 flex flex-col justify-center items-center relative">
          <ul className="space-y-8 text-center md:text-left w-full">
            <li className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 hover:text-sky-900">
              <IoIosHome className="text-2xl mx-auto md:mx-0" />
              <a href="/Admin" className="text-2xl font-bold">
                Home
              </a>
            </li>
            <li className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 hover:text-sky-900">
              <GiProgression className="text-xl mx-auto md:mx-0" />
              <a href="/status" className="text-2xl font-bold">
                Status
              </a>
            </li>
            <li className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 hover:text-sky-900">
              <FaUserPlus className="text-2xl mx-auto md:mx-0" />
              <a href="/inscritos" className="text-2xl font-bold">
                Inscrições
              </a>
            </li>
            <li className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 hover:text-sky-900">
              <FaTicketAlt className="text-2xl mx-auto md:mx-0" />
              <a href="/pagamentos" className="text-2xl font-bold">
                Ingressos
              </a>
            </li>
            <li className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-2 space-y-2 md:space-y-0 hover:text-sky-900">
              <LuCalendarCheck className="text-2xl mx-auto md:mx-0" />
              <a href="/pagina-evento" className="text-2xl font-bold">
                Página do Evento
              </a>
            </li>
          </ul>
        </nav>
      </aside>

      {/* Overlay for mobile when menu is open */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 md:hidden z-30"
          onClick={toggleMenu}
        ></div>
      )}
    </>
  );
}

export default Lateral;
