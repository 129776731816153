import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import api from '../../components/api/api';

function Sede() {
    const navigate = useNavigate();
    const [sedes, setSedes] = useState([]);
    const [selectedSede, setSelectedSede] = useState('');
    const enderecoId = localStorage.getItem('enderecoId');

    useEffect(() => {
        const fetchSedes = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
                    navigate('/login');
                    return;
                }

                const response = await api.get('/sedes', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                // Filtrando apenas sedes com IDs únicos
                const uniqueSedes = response.data.filter((value, index, self) =>
                    index === self.findIndex((t) => t.id === value.id)
                );

                setSedes(uniqueSedes);
            } catch (error) {
                console.error("Erro ao buscar as sedes:", error);
                alert("Erro ao buscar as sedes. Por favor, tente novamente.");
            }
        };

        fetchSedes();
    }, [navigate]);



    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!selectedSede) {
            alert("Por favor, selecione uma sede antes de continuar.");
            return;
        }
    
        const token = localStorage.getItem('token');
    
        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            navigate('/login');
            return;
        }
    
        const sede = sedes.find(sede => sede.id === selectedSede);
        const sedeNome = sede.nome; // Obtém o nome da sede selecionada
    
        if (!sedeNome || !enderecoId) {
            alert("O nome da sede e o ID do endereço são obrigatórios.");
            return;
        }
    
        try {
            // Aqui, não precisamos fazer um POST para adicionar a sede, pois ela já existe
            // Apenas salvamos o ID e o nome da sede no localStorage
            localStorage.setItem('sedeId', selectedSede);
            localStorage.setItem('sedeNome', sedeNome);
    
            // Agora, passe o enderecoId e o nome da sede para a próxima página
            navigate('/criar-evento', { state: { enderecoId, sedeId: selectedSede, sedeNome } }); // Redireciona para a próxima página
        } catch (error) {
            console.error('Erro ao processar a seleção da sede:', error.message);
            alert("Erro ao processar a sede! " + error.message);
        }
    };
    

    const handleSedeChange = (sedeId) => {
        setSelectedSede(sedeId);
        localStorage.setItem('selectedSede', sedeId); // Salva a nova seleção no localStorage
    };

    // Função para deletar uma sede
    const handleDeleteSede = async (sedeId) => {
        const confirmDelete = window.confirm("Tem certeza que deseja excluir esta sede?");
        if (!confirmDelete) return;

        try {
            const token = localStorage.getItem('token');
            if (!token) {
                alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
                navigate('/login');
                return;
            }

            // Fazendo a requisição de exclusão para a API
            await api.delete(`/sedes/${sedeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Atualiza a lista de sedes, removendo a sede excluída
            setSedes(sedes.filter(sede => sede.id !== sedeId));
            alert('Sede excluída com sucesso!');

            // Caso a sede excluída estivesse selecionada, desmarcá-la
            if (selectedSede === sedeId) {
                setSelectedSede('');
                localStorage.removeItem('selectedSede');
            }
        } catch (error) {
            console.error('Erro ao excluir a sede:', error);
            alert('Erro ao excluir a sede. Por favor, tente novamente.');
        }
    };

    const handleAddSede = () => {
        navigate('/criar-sede');
    };

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
                <div className="flex items-center mb-11 ml-8">
                    <IoMdArrowRoundBack className="text-2xl text-blue-500 size-7" />
                    <button
                        onClick={() => navigate(-1)}
                        className="text-xl text-blue-500 hover:text-blue-700 font-bold"
                    >
                        VOLTAR
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">ESCOLHER UMA SEDE</h2>

                    {/* Lista de sedes com botões de seleção e exclusão */}
                    <div className="mt-8 w-full max-w-xs">
                        <ul className="space-y-2">
                            {sedes.map((sede) => (
                                <li key={sede.id} className="flex justify-between items-center border-b py-2">
                                    <span className={`text-lg ${selectedSede === sede.id ? 'font-bold text-blue-600' : ''}`}>
                                        {sede.nome}
                                    </span>
                                    <div className="space-x-2">
                                        {/* Botão de Selecionar */}
                                        <button
                                            className={`px-3 py-1 rounded-full text-white ${selectedSede === sede.id ? 'bg-green-500' : 'bg-blue-500'}`}
                                            onClick={() => handleSedeChange(sede.id)}
                                        >
                                            {selectedSede === sede.id ? 'Selecionado' : 'Selecionar'}
                                        </button>
                                        {/* Botão de Excluir */}
                                        <button
                                            className="bg-red-500 text-white rounded-full px-3 py-1 text-sm"
                                            onClick={() => handleDeleteSede(sede.id)}
                                        >
                                            Excluir
                                        </button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    <div className='flex justify-center items-center mt-6'>
                        <button onClick={handleAddSede} type="button" className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full justify-center flex items-center">ADICIONAR SEDE</button>
                    </div>

                    {/* Botão para continuar */}
                    <div className='flex flex-col justify-center items-end mt-5'>
                        <button onClick={handleSubmit} className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full justify-center flex items-center">PRÓXIMO</button>
                    </div>
                </div>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sede;
