import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import api from '../../components/api/api'; // Certifique-se que o axios está configurado corretamente

function Account() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({
        name: '',
        cpf: '',
        birth_date: '',
        cep: '',
        email: '',
        phone: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);  // Estado para gerenciar envio

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    throw new Error("Token não encontrado. Faça login novamente.");
                }
    
                const response = await api.get('/users', {
                    headers: {
                        authorization: `Bearer ${token}`
                    }
                });
    
                const user = response.data.data.user;
                console.log('Dados do usuário:', user);
                const endereco = response.data.data.endereco[0];
    
                // Converte a data de nascimento para o formato YYYY-MM-DD
                const formattedBirthDate = new Date(user.birth_date).toISOString().split('T')[0];
    
                setUserData({
                    name: user.name,
                    cpf: user.cpf,
                    birth_date: formattedBirthDate, // Data formatada para o input
                    cep: endereco.cep,
                    email: user.email,
                    phone: user.phone
                });
    
                setLoading(false);
            } catch (error) {
                console.error("Erro ao buscar os dados do usuário:", error);
                setError("Erro ao carregar os dados do usuário. Verifique se você está logado.");
                setLoading(false);
            }
        };
        fetchUserData();
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Se já está submetendo, não faça nada
        if (isSubmitting) return;

        // Bloqueia o envio novamente até o fim do processo
        setIsSubmitting(true);

        try {
            const token = localStorage.getItem("token");
    
            const updatedUserData = {
                ...userData,
                birth_date: new Date(userData.birth_date).toISOString(),  // Converte para o formato ISO
            };
    
            await api.put('/users', updatedUserData, {
                headers: {
                    authorization: `Bearer ${token}`
                }
            });
    
            alert("Sua conta foi atualizada com sucesso!");
            navigate(-1);
        } catch (error) {
            console.error("Erro ao atualizar os dados:", error);
            alert("Erro ao atualizar os dados. Tente novamente.");
        } finally {
            // Desbloqueia o envio após 2 segundos
            setTimeout(() => {
                setIsSubmitting(false);
            }, 2000);
        }
    };

    const handleChange = (e) => {
        setUserData({
            ...userData,
            [e.target.name]: e.target.value
        });
    };

    if (loading) {
        return <p>Carregando...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center items-center p-4 md:p-6 lg:p-8">
                <a href="#" onClick={() => navigate(-1)} className="text-sm md:text-base font-bold text-black mb-2 md:mb-4">VOLTAR</a>
                <h2 className="text-lg md:text-xl font-bold mb-2 md:mb-4">MINHA CONTA</h2>
                <form onSubmit={handleSubmit} className="w-full max-w-xs space-y-3 md:space-y-4">
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">Nome Completo</label>
                        <input
                            type="text"
                            name="name"
                            value={userData.name}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">CPF</label>
                        <input
                            type="text"
                            name="cpf"
                            value={userData.cpf}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">Data de Nascimento</label>
                        <input
                            type="date"
                            name="birth_date"
                            value={userData.birth_date}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">CEP</label>
                        <input
                            type="text"
                            name="cep"
                            value={userData.cep}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">E-mail</label>
                        <input
                            type="email"
                            name="email"
                            value={userData.email}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="block mb-1 md:mb-2 text-sm md:text-base">Telefone</label>
                        <input
                            type="tel"
                            name="phone"
                            value={userData.phone}
                            onChange={handleChange}
                            className="w-full p-2 md:p-2.5 border border-gray-300 rounded-lg"
                        />
                    </div>
                    <button
                        type="submit"
                        className={`w-full p-2 md:p-2.5 bg-blue-600 text-white font-bold rounded-lg text-sm md:text-base ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitting} // Desabilita o botão quando está enviando
                    >
                        {isSubmitting ? 'Enviando...' : 'ATUALIZAR DADOS'}
                    </button>
                </form>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;
