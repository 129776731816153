import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer-main";
import api from "../../components/api/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
import { IoMdArrowRoundBack } from "react-icons/io";
import image from "../../assets/FOTO-FUNDO-PRINCIPAL.png";

function Description() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);
  const [ingressos, setIngressos] = useState([]);
  const [quantidades, setQuantidades] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const id = localStorage.getItem("idevento");
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(`/eventos/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setData(response.data.evento);

        const response2 = await api.get(`/ingressos/eventos/${id}/ingressos`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const response3 = await api.get(`/minioRoutas/generate-presigned-url-get/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setImageUrl(response3.data.url);
        const ingressosData = response2.data;
        setIngressos(ingressosData);

        const initialQuantities = {};
        ingressosData.forEach(ingresso => {
          initialQuantities[ingresso.id] = 0;
        });
        setQuantidades(initialQuantities);
      } catch (error) {
        console.error("Erro ao buscar eventos ou ingressos:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const hasSelectedIngresso = Object.values(quantidades).some(q => q > 0);
    setIsButtonDisabled(!hasSelectedIngresso);
  }, [quantidades]);

  const incrementar = (id, max) => {
    setQuantidades(prevQuantidades => {
      const novaQuantidade = prevQuantidades[id] < max ? prevQuantidades[id] + 1 : prevQuantidades[id];
      return { ...prevQuantidades, [id]: novaQuantidade };
    });
  };

  const decrementar = (id) => {
    setQuantidades(prevQuantidades => {
      const novaQuantidade = prevQuantidades[id] > 0 ? prevQuantidades[id] - 1 : 0;
      return { ...prevQuantidades, [id]: novaQuantidade };
    });
  };

  const handleSubmit = () => {
    const selectedQuantities = Object.entries(quantidades)
      .filter(([ingresso_id, quantity]) => quantity !== 0)
      .map(([ingresso_id, quantity]) => ({ ingresso_id, quantity }));
    const total = ingressos.reduce((acc, ingresso) => {
      const quantidade = quantidades[ingresso.id];
      const valor = ingresso.valor;
      return acc + (quantidade * valor);
    }, 0);
    localStorage.setItem("total", total);
    localStorage.setItem("selectedQuantities", JSON.stringify(selectedQuantities));

    navigate("/verifica-pago");
  };

  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800 font-sans leading-normal">
      <header className="container mx-auto py-4 px-4 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="ml-2 text-blue-500 hover:text-blue-700 font-bold text-lg flex items-center"
        >
          <IoMdArrowRoundBack className="size-6" />
          VOLTAR
        </button>
      </header>

      <section className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row bg-white shadow-lg rounded-lg overflow-hidden h-full">
          <div className="w-full md:w-2/3 h-64 md:h-auto relative">
            <img
              src={imageUrl || image}
              className="w-full h-full object-cover rounded-t-lg md:rounded-l-lg md:rounded-t-none"
            />
          </div>
          <div className="flex flex-col justify-center p-4 md:p-8 w-full md:w-1/3 h-full bg-white">
            <div className="flex items-center mb-4">
              <div className="bg-black p-3 rounded-full flex items-center justify-center">
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  color="white"
                  size="lg"
                />
              </div>
              <div className="ml-3">
                <h2 className="font-semibold text-xl">
                  {new Date(data.data_inicio).toLocaleDateString()} |{" "}
                  {new Date(data.data_inicio).toLocaleTimeString()}
                </h2>
              </div>
            </div>
            <h2 className="text-xl md:text-3xl mt-4 font-bold">
              {data.title || "NOME DO EVENTO"}
            </h2>
            <p className="text-sm md:text-xl mt-2">
              {data.description_shorts || "Descrição do evento"}
            </p>
          </div>
        </div>
      </section>
      <div className="container mx-auto mt-6 p-4 md:p-6">
        <h2 className="text-3xl font-bold">Descrição do Evento</h2>
        <p className="text-sm md:text-xl mt-2">
          {data.description || "Descrição do evento"}
        </p>
      </div>

      <section className="container mx-auto mt-6 p-4 md:p-6 bg-gray-100 rounded-lg shadow-md flex-grow">
        <h2 className="text-2xl font-bold mb-4">Ingressos Disponíveis</h2>
        {ingressos.length > 0 ? (
          ingressos.map((ingresso) => (
            <div
              key={ingresso.id}
              className="flex justify-between items-center bg-white p-4 mb-4 rounded-lg shadow-md"
            >
              <div>
                <h3 className="font-bold text-lg">{ingresso.titulo}</h3>
                <p className="text-gray-700">Preço: R${ingresso.valor}</p>
              </div>
              <div className="flex items-center">
                <button
                  className="px-4 py-2 bg-blue-500 text-white font-bold rounded"
                  onClick={() => decrementar(ingresso.id)}
                >
                  -
                </button>
                <span className="mx-2">{quantidades[ingresso.id]}</span>
                <button
                  className="px-4 py-2 bg-blue-500 text-white font-bold rounded"
                  onClick={() => incrementar(ingresso.id, ingresso.qta_max_por_pess)}
                >
                  +
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>Nenhum ingresso disponível no momento.</p>
        )}
        <button
          className={`bg-[#134A6D] text-white px-3 py-2 md:px-6 md:py-3 rounded-full mt-4 text-center ${isButtonDisabled ? "opacity-50 cursor-not-allowed" : ""}`}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          FINALIZAR COMPRA
        </button>
      </section>
      <Footer />
    </div>
  );
}

export default Description;
