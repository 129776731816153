import React, { useState } from 'react';
import { initMercadoPago, Payment, StatusScreen } from '@mercadopago/sdk-react';
import api from '../../components/api/api';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

initMercadoPago('APP_USR-b9af61b7-0f6a-4471-9da6-89e8b9dc18ac');

const CheckoutMercadoPago = () => {
  const [id, setId] = useState('');
  const [isPaymentSelected, setIsPaymentSelected] = useState(false); // New state

  const initialization = {
    amount: localStorage.getItem('total'),
    //preferenceId: "<YOUR_PREFERENCE_ID>"
  };

  const navigate = useNavigate();
  const ticketDetails = JSON.parse(localStorage.getItem('selectedQuantities')) || [];

  const customization = {
    language: 'pt-BR', // Adicionado para definir o idioma como português
    paymentMethods: {
      ticket: 'all',
      bankTransfer: 'all',
      creditCard: 'all',
      debitCard: 'all',
      mercadoPago: 'all',
    },
  };

  const onSubmit = async ({ selectedPaymentMethod, formData }) => {
    setIsPaymentSelected(true);
    return new Promise((resolve, reject) => {
      api.post('/payments/purchase-ticket', {formData:formData,selectedPaymentMethod:selectedPaymentMethod,ticketDetails:ticketDetails}, {
          headers: {
            Authorization:
              `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          console.log(response);
          setId(response.data.id);
          resolve();
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  };

  const onError = async (error) => {
    console.log(error);
  };

  const onReady = async () => { };

  return (
    <div className='flex flex-col items-center justify-center h-screen'>
      <header className="container mx-auto py-4 px-4 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="ml-2 text-blue-500 hover:text-blue-700 font-bold text-lg flex items-center"
        >
          <IoMdArrowRoundBack className="size-6" />
          VOLTAR
        </button>
      </header>
      <div className={`${isPaymentSelected ? 'hidden' : ''}`}>
        <Payment
          initialization={initialization}
          customization={customization}
          onSubmit={onSubmit}
          onReady={onReady}
          onError={onError}
        />
      </div>
      {id && (
        <div>
          <StatusScreen
            initialization={{ paymentId: id }}
            onReady={onReady}
            onError={onError}
          />
        </div>
      )}
    </div>
  );
};

export default CheckoutMercadoPago;
