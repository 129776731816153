import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../components/api/api';
import { IoMdArrowRoundBack } from 'react-icons/io';

function VerificarCompra() {
  const navigate = useNavigate();
  const [disponibilidade, setDisponibilidade] = useState([]);
  const [ingressosLotados, setIngressosLotados] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [erro, setErro] = useState(null);

  // Função para remover ingressos sem disponibilidade
  const filtrarIngressosDisponiveis = (disponibilidade) => {
    return disponibilidade.filter(item => item.DisponipilidadeIngressos > 0);
  };

  // Função para armazenar ingressos disponíveis no localStorage
  const atualizarLocalStorage = (ingressosDisponiveis) => {
    const updatedTicketDetails = ingressosDisponiveis.map(item => {
      return {
        id: item.ingresso1.ingresso.id,
        quantidade: item.quantidadeSolicitada,
        valor: item.ingresso1.ingresso.valor,
        titulo: item.ingresso1.ingresso.titulo,
      };
    });

    localStorage.setItem('selectedQuantities', JSON.stringify(updatedTicketDetails));

    // Atualiza o total no localStorage
    const total = updatedTicketDetails.reduce((acc, item) => acc + (item.valor * item.quantidade), 0);
    localStorage.setItem('total', total);
  };

  useEffect(() => {
    const selectedQuantities = JSON.parse(localStorage.getItem('selectedQuantities')) || [];
    const ticketDetails = { ticketDetails: selectedQuantities };

    const verificarDisponibilidade = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.post('/ingressos/limite', ticketDetails, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Apenas atualizar o estado, não o localStorage
        const ingressosAtualizados = filtrarIngressosDisponiveis(response.data.resultados);
        console.log('ingressosAtualizados:', ingressosAtualizados);
        setDisponibilidade(ingressosAtualizados);
        setIngressosLotados(response.data.ingressolotados);
        setCarregando(false);
      } catch (error) {
        console.error("Erro ao verificar disponibilidade:", error.response);
        setErro(error.data);
        setCarregando(false);
      }
    };

    verificarDisponibilidade();
  }, []);

  // Função chamada ao finalizar a compra
  const finalizarCompra = () => {
    atualizarLocalStorage(disponibilidade);
    navigate('/deposito');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <header className="container mx-auto py-4 px-4 flex items-center">
        <button
          onClick={() => navigate(-1)}
          className="ml-2 text-blue-500 hover:text-blue-700 font-bold text-lg flex items-center"
        >
          <IoMdArrowRoundBack className="size-6" />
          VOLTAR
        </button>
      </header>
      <h1 className="text-2xl font-bold mb-4">Verificação de Compra</h1>

      {carregando ? (
        <p>Verificando disponibilidade...</p>
      ) : erro ? (
        <p className="text-red-500">{erro}</p>
      ) : (
        <>
          <div className="bg-white shadow-md rounded-lg p-6">
            {Array.isArray(disponibilidade) && disponibilidade.map((item, index) => (
              <div key={index} className="mb-4">
                <h3 className="text-lg font-semibold">{item.ingresso1.ingresso.titulo}</h3>
                <p>{item.DisponipilidadeIngressos} ingressos disponíveis.</p>
                {item.DisponipilidadeIngressos === 0 ? (
                  <p className="text-red-500">Ingressos esgotados para {item.ingresso1.ingresso.titulo}.</p>
                ) : (
                  <p className="text-green-500">Quantidade suficiente disponível.</p>
                )}
              </div>
            ))}
          </div>

          {ingressosLotados.length > 0 && (
            <div className="bg-red-100 p-4 mt-4">
              <h2 className="text-lg font-semibold text-red-600">Ingressos Lotados</h2>
              <p className="text-red-500 mb-2">
                Atenção: Ao avançar, os ingressos sem disponibilidade serão removidos automaticamente.
              </p>
              <ul className="list-disc pl-5">
                {ingressosLotados.map((item, index) => (
                  <li key={index} className="text-red-500">{item}</li>
                ))}
              </ul>
            </div>
          )}

          <button onClick={finalizarCompra}
            className="bg-blue-500 text-white px-6 py-3 rounded mt-4"
          >
            Finalizar Compra
          </button>
        </>
      )}
    </div>
  );
}

export default VerificarCompra;
