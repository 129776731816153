import React, { useState, useEffect } from 'react';
import api from '../../components/api/api'; // Certifique-se que o axios está configurado corretamente

const Local = () => {
    const [addressDetails, setAddressDetails] = useState({
        logradouro: '',
        bairro: '',
        localidade: '',
        uf: '',
        cep: '',
        status: ''
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await api.get(`/eventos/${localStorage.getItem("idevento")}`);
                const { logradouro, bairro, localidade, uf, cep, status } = response.data.endereco;
                setAddressDetails({ logradouro, bairro, localidade, uf, cep, status });
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar detalhes do endereço:', error);
                setError('Erro ao carregar os detalhes do endereço.');
                setLoading(false);
            }
        };

        fetchEventDetails();
    }, []);

    const handleSave = async () => {
        const token = localStorage.getItem("token");
        try {
            const response = await api.put(
                `/eventos/${localStorage.getItem("idevento")}`,
                {
                    logradouro: addressDetails.logradouro,
                    bairro: addressDetails.bairro,
                    localidade: addressDetails.localidade,
                    uf: addressDetails.uf,
                    cep: addressDetails.cep,
                    status: addressDetails.status
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('Endereço atualizado:', response.data);
            setIsEditing(false);
        } catch (error) {
            console.error('Erro ao salvar os detalhes do endereço:', error);
            setError('Erro ao salvar os detalhes do endereço.');
        }
    };

    if (loading) {
        return <p className="text-gray-700">Carregando...</p>;
    }

    if (error) {
        return <p className="text-red-600">{error}</p>;
    }

    return (
        <div className="bg-white shadow-md rounded-lg p-6 mb-4">
            <h2 className="text-2xl font-bold mb-4">Local do Evento</h2>

            {isEditing ? (
                <div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Endereço:</label>
                        <input
                            type="text"
                            value={addressDetails.logradouro}
                            onChange={(e) => setAddressDetails({ ...addressDetails, logradouro: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Bairro:</label>
                        <input
                            type="text"
                            value={addressDetails.bairro}
                            onChange={(e) => setAddressDetails({ ...addressDetails, bairro: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Cidade:</label>
                        <input
                            type="text"
                            value={addressDetails.localidade}
                            onChange={(e) => setAddressDetails({ ...addressDetails, localidade: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">Estado:</label>
                        <input
                            type="text"
                            value={addressDetails.uf}
                            onChange={(e) => setAddressDetails({ ...addressDetails, uf: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700">CEP:</label>
                        <input
                            type="text"
                            value={addressDetails.cep}
                            onChange={(e) => setAddressDetails({ ...addressDetails, cep: e.target.value })}
                            className="border border-gray-300 rounded p-2 w-full"
                        />
                    </div>
                    <button
                        onClick={handleSave}
                        className="mt-2 bg-green-500 text-white rounded p-2"
                    >
                        Salvar
                    </button>
                    <button
                        onClick={() => setIsEditing(false)}
                        className="mt-2 ml-2 bg-gray-500 text-white rounded p-2"
                    >
                        Cancelar
                    </button>
                </div>
            ) : (
                <div>
                    <p className="text-gray-700">Endereço: {addressDetails.logradouro}</p>
                    <p className="text-gray-700">Bairro: {addressDetails.bairro}</p>
                    <p className="text-gray-700">Cidade: {addressDetails.localidade}</p>
                    <p className="text-gray-700">Estado: {addressDetails.uf}</p>
                    <p className="text-gray-700">CEP: {addressDetails.cep}</p>
                    <button
                        onClick={() => setIsEditing(true)}
                        className="mt-4 bg-blue-500 text-white rounded p-2"
                    >
                        Editar
                    </button>
                </div>
            )}
        </div>
    );
};

export default Local;
