import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Perfil from "../../assets/perfil_branco.png";
import "tailwindcss/tailwind.css";
import { Transition } from "@headlessui/react";
import api from '../../components/api/api';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const handleLogout = () => {
        localStorage.removeItem("token");
        window.location.reload();
    };
    useEffect(() => {
        const fetchUserProfile = async () => {
            if (localStorage.getItem("token")) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
    
        fetchUserProfile();
    }, []);

    const getLinkClass = (path) => {
        return location.pathname === path
            ? "text-lg md:text-xl font-bold underline underline-offset-4"
            : "text-lg md:text-xl font-bold";
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleProfileMenu = () => {
        setIsProfileOpen(!isProfileOpen);
    };

    return (
        <header className="fixed top-0 left-0 w-full text-white z-50 bg-transparent mt-1">
            <div className="container mx-auto flex justify-between items-center px-4 md:px-10 py-6">
                <div className="flex items-center order-1 md:order-none">
                    <div className="md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="text-white focus:outline-none"
                        >
                            <svg
                                className="w-8 h-8"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16m-7 6h7"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="hidden md:flex space-x-4 lg:space-x-8">
                        <a
                            href=""
                            className={getLinkClass("/")}
                            onClick={() => navigate("/")}
                        >
                            INÍCIO
                        </a>
                        <a
                            href=""
                            className={getLinkClass("/eventos")}
                            onClick={() => navigate("/eventos")}
                        >
                            EVENTOS
                        </a>
                        <a
                            href="https://www.kaiomarcio.com.br/"
                            target="_blank"
                            className={getLinkClass("")}
                        >
                            LOJA
                        </a>
                        {!isLoggedIn && (
                            <a
                                href=""
                                className={getLinkClass("/login")}
                                onClick={() => navigate("/login")}
                            >
                                LOGIN
                            </a>
                        )}
                    </div>
                </div>
                {isLoggedIn && (
                    <div className="flex items-center relative order-2 md:order-none">
                        <div
                            className="bg-black rounded-full flex items-center justify-center cursor-pointer"
                            onClick={toggleProfileMenu}
                        >
                            <img className="h-8 w-8 md:h-10 md:w-10" src={Perfil} alt="Perfil" />
                        </div>
                        {isProfileOpen && (
                            <div className="absolute top-12 right-0 bg-white text-black rounded-lg shadow-lg p-4 w-48">
                                <div className="flex items-center space-x-2 mb-4">
                                    <svg
                                        className="w-6 h-6 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M12 12c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0 2c-3.31 0-10 1.67-10 5v2h20v-2c0-3.33-6.69-5-10-5z" />
                                    </svg>
                                    <a href="/minha-conta" className="text-lg font-bold">
                                        MINHA CONTA
                                    </a>
                                </div>
                                <hr className="my-2" />
                                <div className="flex items-center space-x-2 mb-4">
                                    <svg
                                        className="w-6 h-6 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2z" />
                                    </svg>
                                    <a href="/meus-ingressos" className="text-lg font-bold">
                                        MEUS INGRESSOS
                                    </a>
                                </div>
                                <hr className="my-2" />
                                <div className="flex items-center space-x-2 mb-4">
                                    <svg
                                        className="w-6 h-6 text-gray-500"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                    >
                                        <path d="M10 9V7h4v2h5v12H5V9h5zm2-7c-1.1 0-1.99.9-1.99 2L10 5h4c1.1 0 2-.9 2-2s-.9-2-2-2zm-4 14h2v2H8v-2zm8 0h2v2h-2v-2zM5 11h14v2H5v-2z" />
                                    </svg>
                                    <button onClick={handleLogout} className="text-lg font-bold">
                                        SAIR
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <Transition show={isOpen}>
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40"
                    onClick={toggleMenu}
                ></div>
                <div className={`fixed inset-y-0 left-0 w-64 bg-black p-4 z-50 transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
                    <button
                        onClick={toggleMenu}
                        className="text-white mb-4 focus:outline-none"
                    >
                        <svg
                            className="w-8 h-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                    <nav className="flex flex-col space-y-4">
                        <a
                            href=""
                            className={getLinkClass("/")}
                            onClick={() => {
                                navigate("/");
                                toggleMenu();
                            }}
                        >
                            INÍCIO
                        </a>
                        <a
                            href=""
                            className={getLinkClass("/eventos")}
                            onClick={() => {
                                navigate("/eventos");
                                toggleMenu();
                            }}
                        >
                            EVENTOS
                        </a>
                        <a
                            href="https://www.kaiomarcio.com.br/"
                            target="_blank"
                            className={getLinkClass("")}
                        >
                            LOJA
                        </a>
                        {!isLoggedIn && (
                            <a
                                href=""
                                className={getLinkClass("/login")}
                                onClick={() => {
                                    navigate("/login");
                                    toggleMenu();
                                }}
                            >
                                LOGIN
                            </a>
                        )}
                    </nav>
                </div>
            </Transition>
        </header>
    );
}

export default Header;
