import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/LogoIconBranca.png';
import ImagemFundo from '../../assets/FOTO-FUNDO-PRINCIPAL.png';
import { IoMdArrowRoundBack } from "react-icons/io";
import api from '../../components/api/api';

function CriarIngresso() {
    const navigate = useNavigate();
    const [nomeIngresso, setNomeIngresso] = useState('');
    const [tituloIngresso, setTituloIngresso] = useState('');
    const [valorIngresso, setValorIngresso] = useState('');
    const [qtdMax, setQtdMax] = useState('');
    const [qtdMaxPessoa, setQtdMaxPessoa] = useState('');
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [isFree, setIsFree] = useState(false);

    const handleFreeChange = (e) => {
        const isChecked = e.target.value === 'true';
        setIsFree(isChecked);

        if (isChecked) {
            setValorIngresso('0');
        } else {
            setValorIngresso('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const eventoId = localStorage.getItem('idevento');
        const token = localStorage.getItem('token');

        if (!token) {
            alert("Token de autenticação não encontrado. Por favor, faça login novamente.");
            navigate('/login');
            return;
        }

        const ingresso = {
            nome: nomeIngresso || "Ingresso caro",
            titulo: tituloIngresso || "Entrada para o dia da sua vida",
            valor: parseFloat(valorIngresso) || 300.00,
            qta_max: parseInt(qtdMax) || 1,
            qta_max_origin: parseInt(qtdMax) || 1,
            qta_max_por_pess: parseInt(qtdMaxPessoa) || 1,
            is_free: isFree,
            evento_id: eventoId,
            data_inicio: dataInicio,
            data_fim: dataFim
        };

        try {
            const response = await api.post('/ingressos', ingresso, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 201 || response.status === 200) {
                alert('Ingresso criado com sucesso!', response.data);
                navigate('/pagamentos');
            } else {
                console.error('Erro na resposta da API:', response);
                alert("Erro ao enviar ingresso! Status: " + response.status);
            }
        } catch (error) {
            console.error('Erro ao enviar ingresso:', error.response ? error.response.data : error.message);
            alert("Erro ao enviar ingresso! " + (error.response?.data?.message || error.message));
        }
    };

    return (
        <div className="flex flex-col md:flex-row h-screen w-full">
            <div className="flex-1 md:w-1/3 bg-white flex flex-col justify-center p-4 md:p-6 lg:p-8">
                <div className="flex items-center mb-11 ml-8">
                    <IoMdArrowRoundBack className="text-2xl text-blue-500 size-7" />
                    <button
                        onClick={() => navigate(-1)}
                        className="text-xl text-blue-500 hover:text-blue-700 font-bold text-lg"
                    >
                        VOLTAR
                    </button>
                </div>
                <div className="flex flex-col items-center">
                    <h2 className="text-2xl md:text-3xl font-bold mb-2 md:mb-7">CRIAR INGRESSO</h2>
                    <form onSubmit={handleSubmit} className="w-full max-w-xs space-y-4">
                        <div>
                            <label htmlFor="nomeIngresso" className="block text-sm font-bold mb-1 ml-4">NOME DO INGRESSO</label>
                            <input
                                type="text"
                                id="nomeIngresso"
                                className="w-full border rounded-full px-3 py-2"
                                value={nomeIngresso}
                                onChange={(e) => setNomeIngresso(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="tituloIngresso" className="block text-sm font-bold mb-1 ml-4">TÍTULO DO INGRESSO</label>
                            <input
                                type="text"
                                id="tituloIngresso"
                                className="w-full border rounded-full px-3 py-2"
                                value={tituloIngresso}
                                onChange={(e) => setTituloIngresso(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="valorIngresso" className="block text-sm font-bold mb-1 ml-4">VALOR DO INGRESSO</label>
                            <input
                                type="number"
                                id="valorIngresso"
                                className="w-full border rounded-full px-3 py-2"
                                value={valorIngresso}
                                onChange={(e) => setValorIngresso(e.target.value)}
                                disabled={isFree}
                            />
                        </div>
                        <div>
                            <label htmlFor="qtdMax" className="block text-sm font-bold mb-1 ml-4">QUANTIDADE MÁXIMA</label>
                            <input
                                type="number"
                                id="qtdMax"
                                className="w-full border rounded-full px-3 py-2"
                                value={qtdMax}
                                onChange={(e) => setQtdMax(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="qtdMaxPessoa" className="block text-sm font-bold mb-1 ml-4">QUANTIDADE MÁXIMA POR PESSOA</label>
                            <input
                                type="number"
                                id="qtdMaxPessoa"
                                className="w-full border rounded-full px-3 py-2"
                                value={qtdMaxPessoa}
                                onChange={(e) => setQtdMaxPessoa(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="dataInicio" className="block text-sm font-bold mb-1 ml-4">DATA DE INÍCIO</label>
                            <input
                                type="date"
                                id="dataInicio"
                                className="w-full border rounded-full px-3 py-2"
                                value={dataInicio}
                                onChange={(e) => setDataInicio(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="dataFim" className="block text-sm font-bold mb-1 ml-4">DATA DE FIM</label>
                            <input
                                type="date"
                                id="dataFim"
                                className="w-full border rounded-full px-3 py-2"
                                value={dataFim}
                                onChange={(e) => setDataFim(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="isFree" className="block text-sm font-bold mb-1 ml-4">É GRATUITO?</label>
                            <select
                                id="isFree"
                                className="w-full border rounded-full px-3 py-2"
                                value={isFree.toString()}
                                onChange={handleFreeChange}
                            >
                                <option value="false">Não</option>
                                <option value="true">Sim</option>
                            </select>
                        </div>
                        <div className='flex justify-center items-center'>
                            <button type="submit" className="w-40 h-10 bg-blue-500 text-white font-bold rounded-full mt-4 justify-center flex items-center">PRÓXIMO</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="hidden md:flex md:w-2/3 relative">
                <div className="w-full h-full flex items-center justify-center relative bg-cover bg-center" style={{ backgroundImage: `url(${ImagemFundo})` }}>
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <img src={Logo} alt="Kaio Marcio Logo" className="max-w-full h-auto p-4 md:p-6" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CriarIngresso;
