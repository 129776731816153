import React, { useState, useEffect } from "react";
import logo from "../../assets/LogoIconBranca.png";
import Lateral from "../../components/lateral/index";
import { HiMiniUserGroup } from "react-icons/hi2";
import { FaRegCreditCard } from "react-icons/fa6";
import { GiOpenBook } from "react-icons/gi";
import { FaLightbulb } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import api from "../../components/api/api";

const Dashboard = () => {
  const [inscritos, setInscritos] = useState(0);
  const [valorLiquido, setValorLiquido] = useState(0);
  const [showValorLiquido, setShowValorLiquido] = useState(false);

  useEffect(() => {
    const eventoId = localStorage.getItem('idevento');

    // Função para buscar os ingressos do evento pelo evento_id
    const fetchIngressos = async () => {
      try {
        const response = await api.get(`/ingressos/eventos/${eventoId}/ingressos`);
        const ingressos = response.data;
        console.log(ingressos);
        const formattedEvents = ingressos.map(ingresso => ({
          id: ingresso.id,
          name: ingresso.titulo,
          value: ingresso.valor,
          capacity: ingresso.qta_max,
          sold: ingresso.participantesNumber,
        }));
        const totalSold = formattedEvents.reduce((total, ingresso) => total + ingresso.sold, 0);
        setInscritos(totalSold);
        const totalArrecadado = formattedEvents.reduce((total, ingresso) => total + (ingresso.value * ingresso.sold), 0);
        setValorLiquido(totalArrecadado);
      } catch (error) {
        console.error('Erro ao buscar ingressos!', error);
      }
    };

    if (eventoId) {
      fetchIngressos();
    } else {
      console.error('Evento ID não encontrado no localStorage');
    }
  }, []);


  return (
    <div className="min-h-screen flex flex-col md:flex-row bg-gray-100">
      <Lateral />
      <main className="flex-1 md:ml-72">
        <div className="flex justify-end">
          <img
            src={logo}
            alt="Kaio Marcio"
            className="h-24 md:h-36 bg-sky-900 rounded-ee-3xl rounded-es-3xl mr-40 hidden md:block"
          />
        </div>
        <div className="pl-8 pr-8">
          <header className="flex items-center justify-between mb-8">
            <h1 className="text-5xl font-bold text-sky-900">Status</h1>
          </header>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
              <HiMiniUserGroup className="text-5xl text-sky-900" />
              <div className="ml-4 text-right">
                <div className="text-3xl font-semibold text-sky-900">
                  {inscritos}
                </div>
                <div className="text-gray-600">Participantes inscritos</div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex items-center justify-between">
              <FaRegCreditCard className="text-5xl text-sky-900" />
              <div className="ml-4 flex-1">
                <div className="text-3xl font-semibold text-sky-900">
                  {showValorLiquido ? `R$${valorLiquido.toFixed(2)}` : "***"}
                </div>
                <div className="text-gray-600">Valor Líquido</div>
              </div>
              <button
                className="bg-white text-sky-900 border border-sky-900 px-4 py-2 rounded ml-4 flex items-center"
                onClick={() => setShowValorLiquido(!showValorLiquido)}
              >
                <FaEye className="mr-2" />
                {showValorLiquido ? "Esconder" : "Mostrar"}
              </button>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex items-center">
              <GiOpenBook className="text-5xl text-sky-900" />
              <div className="ml-4">
                <div className="text-3xl font-semibold text-sky-900">
                  Central de Ajuda
                </div>
                <div className="text-gray-600">
                  Tire todas as suas dúvidas aqui
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md flex items-center">
              <FaLightbulb className="text-5xl text-sky-900" />
              <div className="ml-4">
                <div className="text-3xl font-semibold text-sky-900">
                  Portal de Ideias
                </div>
                <div className="text-gray-600">
                  Espaço para sugestão de melhorias no produto
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
